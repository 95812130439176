import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PriceService } from '../price.service';
import { CalculatedInformationModel, CalculatedResponseModel, NavigateEventType, PlaceOrderModel, PriceItemModel } from '../model/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { globalPhoneControlValidationMessages } from '@roadrecord/common/common';
import { phoneNumberValidator3 } from '@roadrecord/ngx-phone-validators';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { isNil, isNotEmptyString } from '@roadrecord/type-guard';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, skip, startWith, take } from 'rxjs/operators';
import { PriceStepEnum } from '../model/price-step.enum';
// @ts-ignore
import { STATE_LIST } from '../model/state-list-us';
import { Store } from '@ngxs/store';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'rr-order-form',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [PriceService],
  encapsulation: ViewEncapsulation.Emulated,
})
export class OrderComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input()
  priceList: PriceItemModel[] = [];

  @Input()
  selectedPricePackage: PriceItemModel;

  @Input()
  isBasicPrice = true;

  @Input()
  showInStepper = false;

  @Output()
  navigateEvent = new EventEmitter<NavigateEventType>();

  phoneControlValidationMessages = globalPhoneControlValidationMessages;
  calculatedInformation: CalculatedInformationModel = null;

  filteredStateList: Observable<string[]>;
  readonly stateList = STATE_LIST;
  readonly loading$ = new BehaviorSubject(false);
  readonly loadingCalculate$ = new BehaviorSubject(false);
  readonly isAmericanVersion = environment.appType === AppTypeEnum.US;

  form: FormGroup;

  firstNameControl = new FormControl(null, [Validators.required, Validators.minLength(2)]);
  lastNameControl = new FormControl(null, [Validators.required, Validators.minLength(2)]);
  phoneNumberControl = new FormControl(null);
  streetControl1 = new FormControl(null, Validators.required);
  streetControl2 = new FormControl(null);
  cityControl = new FormControl(null, Validators.required);
  zipControl = new FormControl(null, Validators.required);
  stateControl = new FormControl(null, Validators.required);
  numberOfVehicleControl = new FormControl(1, Validators.required);
  subscriptionStartDateControl = new FormControl(null, Validators.required);
  emailControl = new FormControl(undefined, [Validators.required, EmailValidators.normal]);
  companyNameControl = new FormControl(null);
  selectedPackageNameControl = new FormControl(null);
  paymentTypeControl = new FormControl('STRIPE', Validators.required);

  stateInputControl = new FormControl(null, [Validators.required]);
  stateControlInvalidValueMessages = '';
  constructor(
    private priceService: PriceService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store,
    readonly translocoService: TranslocoService
  ) {
    this.initForm();
    this.stateControlInvalidValueMessages = this.translocoService.translate(
      '' + 'PRICE.DATA_FORM.ORDER.FIELD.STATE.VALIDATION_ERRORS.INVALID_VALUE'
    );
  }

  private getSelectedPackageBillingInformation(): void {
    const subscriptionStartValue = isNil(this.subscriptionStartDateControl.value) ? new Date() : this.subscriptionStartDateControl.value;

    const forkJoinStreamList: Observable<any>[] = [
      this.priceService.getBillingInformation(),
      this.priceService.getCalculatePrice(
        moment(subscriptionStartValue).format('YYYY-MM-DD'),
        this.selectedPricePackage.id,
        this.numberOfVehicleControl.value
      ),
    ];

    this.loading$.next(true);

    forkJoin(forkJoinStreamList).subscribe(
      results => {
        const billingInformationResponse = results[0];
        const calculatedResponse = results[1];

        if (isNotEmptyString(billingInformationResponse.first_name)) {
          this.firstNameControl.patchValue(billingInformationResponse.first_name, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.last_name)) {
          this.lastNameControl.patchValue(billingInformationResponse.last_name, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.email)) {
          this.emailControl.patchValue(billingInformationResponse.email, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.phone_number)) {
          this.phoneNumberControl.patchValue(billingInformationResponse.phone_number, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.company_name)) {
          this.companyNameControl.patchValue(billingInformationResponse.company_name, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.address_line1)) {
          this.streetControl1.patchValue(billingInformationResponse.address_line1, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.address_line2)) {
          this.streetControl2.patchValue(billingInformationResponse.address_line2, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.city)) {
          this.cityControl.patchValue(billingInformationResponse.city, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.state)) {
          this.stateControl.patchValue(billingInformationResponse.state, { emitEvent: false });
          this.stateInputControl.patchValue(billingInformationResponse.state, { emitEvent: false });
        }

        if (isNotEmptyString(billingInformationResponse.zip)) {
          this.zipControl.patchValue(billingInformationResponse.zip, { emitEvent: false });
        }

        this.privateSetCalculateInformationModel(calculatedResponse);

        this.loading$.next(false);
      },
      error => {
        console.error(error);
        this.loading$.next(false);
        MaybeHandleHttpError.maybeHandleHttpError(error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes['selectedPricePackage']) && !isNil(changes['selectedPricePackage'].currentValue)) {
      const selectedPackage = this.priceList.find(item => item.id === this.selectedPricePackage.id);
      //this.selectedPackageNameControl.patchValue(this.selectedPricePackage.name, { emitEvent: false });
      this.selectedPackageNameControl.patchValue(selectedPackage, { emitEvent: false });

      if (changes['selectedPricePackage'].currentValue.subscription_start_date) {
        this.subscriptionStartDateControl.patchValue(changes['selectedPricePackage'].currentValue.subscription_start_date, {
          emitEvent: false,
        });
      }
      this.getSelectedPackageBillingInformation();
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.numberOfVehicleControl.valueChanges.pipe(startWith(1)),
      this.subscriptionStartDateControl.valueChanges.pipe(startWith(this.subscriptionStartDateControl.value)),
    ])
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((values: [number, Date]) => {
        const vehicleCount = values[0];
        const subscriptionStartDate = isNil(values[1]) ? new Date() : values[1];

        this.loadingCalculate$.next(true);
        this.selectedPricePackage.vehicle_count = vehicleCount;
        this.selectedPricePackage.subscription_start_date = values[1];

        this.priceService
          .getCalculatePrice(moment(subscriptionStartDate).format('YYYY-MM-DD'), this.selectedPricePackage.id, vehicleCount)
          .subscribe(
            result => {
              this.privateSetCalculateInformationModel(result);
              this.loadingCalculate$.next(false);
            },
            error => {
              console.error(error);
              this.loadingCalculate$.next(false);
              MaybeHandleHttpError.maybeHandleHttpError(error);
            }
          );
      });

    if (this.isAmericanVersion) {
      this.phoneNumberControl.setValidators(Validators.compose([phoneNumberValidator3]));
      this.phoneControlValidationMessages = [
        {
          errorKey: 'phoneNumber',
          translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
        },
      ];
    }
    this.cdr.detach();

    this.selectedPackageNameControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.selectedPricePackage = value;
      this.getSelectedPackageBillingInformation();
    });

    this.filteredStateList = this.stateInputControl.valueChanges.pipe(
      untilDestroyed(this),
      startWith(''),
      map(value => {
        this.stateControl.reset();
        this.stateInputControl.setErrors({
          ...this.stateInputControl.errors,
          customError: this.stateControlInvalidValueMessages,
        });
        return this.stateFilter(value);
      })
    );
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  private stateFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return STATE_LIST.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
   *
   * @private
   */
  private initForm(): void {
    this.emailControl.disable();

    this.form = this.fb.group({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      email: this.emailControl,
      phone_number: this.phoneNumberControl,
      company_name: this.companyNameControl,
      city: this.cityControl,
      zip: this.zipControl,
      state: this.stateControl,
      address_line1: this.streetControl1,
      address_line2: this.streetControl2,
      vehicle_count: this.numberOfVehicleControl,
      subscription_start_date: this.subscriptionStartDateControl,
      selected_package_name: this.selectedPackageNameControl,
      payment_provider_type: this.paymentTypeControl,
    });
  }

  /**
   *
   * @param pricesInformation
   * @private
   */
  private privateSetCalculateInformationModel(pricesInformation: CalculatedResponseModel): void {
    if (!isNil(pricesInformation)) {
      this.calculatedInformation = {
        package_name: this.selectedPricePackage.longname,
        vehicle_count: this.numberOfVehicleControl.value,
        ...pricesInformation,
      };
    } else {
      this.calculatedInformation = null;
    }
  }

  /**
   * US telefonszám formátumra alakítása
   * @param value
   */
  parseUsPhoneNumber(value: string) {
    return value.replace(new RegExp(/-/, 'g'), '').replace(new RegExp(/\(/, 'g'), '').replace(new RegExp(/\)/, 'g'), '');
  }

  /**
   * Árlistára navigálás
   */
  onNavigatePriceList() {
    const event: NavigateEventType = {
      step: PriceStepEnum.PACKAGE_LIST,
      data: this.selectedPricePackage,
    };
    this.navigateEvent.emit(event);
  }

  onSubmit() {
    if (this.form.valid) {
      const payload: PlaceOrderModel = {
        ...this.form.getRawValue(),
        tax_code: '123467',
        phone_number:
          !isNil(this.phoneNumberControl.value) && this.phoneNumberControl.value.indexOf('_') > -1
            ? this.phoneNumberControl.value.substring(0, this.phoneNumberControl.value.indexOf('_'))
            : this.phoneNumberControl.value,
        product_id: this.selectedPricePackage.id,
        vehicle_count: this.numberOfVehicleControl.value,
        subscription_start_date: moment(this.subscriptionStartDateControl.value).format('YYYY-MM-DD'),
      };

      this.loading$.next(true);
      this.priceService.placeOrder(payload).subscribe(
        responsePlaceOrder => {
          this.priceService.executeOrder(responsePlaceOrder.id, this.paymentTypeControl.value).subscribe(
            response => {
              this.loading$.next(false);
              if (this.showInStepper === true) {
                sessionStorage.setItem('payment-in-stepper', 'true');
              }
              window.location.href = response.redirect_url;
            },
            error => {
              console.error(error);
              this.loading$.next(false);
              MaybeHandleHttpError.maybeHandleHttpError(error);
            }
          );
        },
        error => {
          console.error(error);
          this.loading$.next(false);
          MaybeHandleHttpError.maybeHandleHttpError(error);
        }
      );
    }
  }
  onStateSelected(event: MatAutocompleteSelectedEvent) {
    const selectedState = event.option.value;
    this.stateInputControl.setErrors(null);
    this.form.controls['state'].setValue(selectedState);
    this.stateInputControl.patchValue(selectedState, { emitEvent: false });
  }
}
