import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isNil } from '@roadrecord/type-guard';
import { SelectedTripsEventTypeEnum } from '../../model/salected-trips-event.enum';
import { laTripReasonOptionsConfig, TripReasonService } from '@roadrecord/trip-reason/common';
import { SelectedTripActionModel } from '../../model/selected-trips-event.model';
import { Observable, race } from 'rxjs';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  FragmentDialogTypeEnum,
  FragmentHideDialogAction,
  FragmentRemoveDialogAction,
  FragmentShowDialogAction,
} from '@roadrecord/fragment-dialog';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PartnerService } from '@roadrecord/partner/common';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { HttpListResponseModel } from '@roadrecord/utils';
import { PartnerListModel, PartnerModel } from '@roadrecord/partner/model';
import { liveAutoCompleteDiFactoryReturnType, LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG } from '@roadrecord/partner/live-auto-complete';
import { SelectedTripsActionTypeEnum } from '../../model/selected-trip-action-type.enum';
import { DATE_FORMAT } from '@roadrecord/common/common';
import moment from 'moment';
import { MessageDialogService } from '@roadrecord/message-dialog';

@UntilDestroy()
@Component({
  selector: 'rr-floating-action',
  templateUrl: './floating-action.component.html',
  styleUrls: ['./floating-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PartnerService, TripReasonService],
})
export class FloatingActionComponent implements OnInit, OnChanges {
  @Input() selectedTrips: FormGroup;
  @Input() actionCompleted: Date | null = null;
  @Output() selectedTripEvent = new EventEmitter<SelectedTripActionModel>();

  isShow = false;
  isDisabled = false;

  form: FormGroup;
  FloatingActionEnum = SelectedTripsActionTypeEnum;
  floatingActionControl = new FormControl(null);

  tripReasonControl = new FormControl(undefined, Validators.required);
  partnerControl = new FormControl(undefined, Validators.required);

  laTripReasonOptionsConfig = laTripReasonOptionsConfig;
  laPartnerOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
    ...this._laPartnerOptionsConfig(),
    optionValueBindFn: model => model,
  });

  private openedNewDialog = false;
  private readonly selectedDay = moment(new Date()).format(DATE_FORMAT);

  constructor(
    private store: Store,
    readonly actions$: Actions,
    private cdr: ChangeDetectorRef,
    readonly partnerService: PartnerService,
    readonly tripReasonService: TripReasonService,
    private messageDialogService: MessageDialogService,
    @Inject(PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG)
    private _laPartnerOptionsConfig: liveAutoCompleteDiFactoryReturnType<PartnerListModel>
  ) {
    this.initForm();
  }

  private clearForm(): void {
    this.isDisabled = false;
    this.partnerControl.patchValue(null, { emitEvent: false });
    this.tripReasonControl.patchValue(null, { emitEvent: false });
    this.floatingActionControl.patchValue(null, { emitEvent: false });
  }

  private initForm(): void {
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.floatingActionControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(result => {
      switch (result) {
        case SelectedTripsActionTypeEnum.TRIP_REASON:
          this.form.removeControl('partner');
          this.form.addControl('trip_reason', this.tripReasonControl);
          break;
        case SelectedTripsActionTypeEnum.PARTNER:
          this.form.removeControl('trip_reason');
          this.form.addControl('partner', this.partnerControl);
          break;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['selectedTrips'])) {
      const selectedTrips = changes['selectedTrips'].currentValue;
      if (!isNil(selectedTrips)) {
        this.isDisabled = false;
        this.isShow = selectedTrips['selectionTripForm'].controls['selectedCount'].value > 0;
      }
    } else if (!isNil(changes['actionCompleted'])) {
      this.clearForm();
    }
  }

  onSelectAction(floatingAction: SelectedTripsActionTypeEnum): void {
    this.floatingActionControl.patchValue(floatingAction);
  }

  /**
   * Ablak bezárása kijelölt elemek törlése
   */
  closeFloatingWindow() {
    (this.selectedTrips['selectionTripForm'].controls['selectionControls'] as FormArray).controls.forEach(group => {
      (group as FormGroup).controls['checked'].patchValue(false);
    });
    this.clearForm();
  }

  getPartnerLiveAutocompleteCallback(): (
    sort: Sort,
    page: PageEvent,
    simpleAllFilterValue: string
  ) => Observable<HttpListResponseModel<PartnerModel>> {
    return this.getPartnerLiveAutocompleteList.bind(this);
  }

  getPartnerLiveAutocompleteList(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<HttpListResponseModel<PartnerListModel>> {
    sort.active = 'name';
    return this.partnerService.getAllWithExtraQueryParams(sort, page, simpleAllFilterValue, {
      'finalization-of-route': this.selectedDay,
      expand: 'trip_reason',
    });
  }

  /**
   * Mentés esemény emitálása kifelé
   */
  onSaveSelectedTrips() {
    if (this.form.valid) {
      const selectTripEvent: SelectedTripActionModel = {
        event: SelectedTripsEventTypeEnum.SAVE,
        actionType: this.floatingActionControl.value,
        trip_reason_id: this.tripReasonControl.value,
        partner_id: this.partnerControl.value?.id,
      };
      this.isDisabled = true;
      this.selectedTripEvent.emit(selectTripEvent);
    }
  }

  /**
   * Partner ablak megnyitása
   * @param search {string}
   */
  onOpenNewPartner(search: string): void {
    race([
      this.actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER && this.openedNewDialog === true)
      ),
      this.actions$.pipe(
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER && this.openedNewDialog === true)
      ),
    ])
      .pipe(untilDestroyed(this), take(1))
      .subscribe((action: FragmentHideDialogAction | FragmentRemoveDialogAction) => {
        this.openedNewDialog = false;
        this.cdr.markForCheck();
        if (
          (action instanceof FragmentHideDialogAction && (action.cancel === true || action.locationBack === true)) ||
          action instanceof FragmentRemoveDialogAction
        ) {
          return;
        }
        this.partnerService
          .getByIds([action.data.id], 'expand=trip_reason')
          .pipe(
            map(response => (response.results.length > 0 ? response.results[0] : null)),
            untilDestroyed(this)
          )
          .subscribe(newPartner => this.partnerControl.patchValue(newPartner));
      });
    this.openedNewDialog = true;
    this.store.dispatch(new FragmentShowDialogAction(FragmentDialogTypeEnum.NEW_PARTNER, undefined, { search }));
  }

  /**
   * Útvonal indok ablak megnyitása
   * @param search {string}
   */
  onOpenNewTripReason(search: string): void {
    race([
      this.actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(filterAction => filterAction.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON && this.openedNewDialog === true)
      ),
      this.actions$.pipe(
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(filterAction => filterAction.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON && this.openedNewDialog === true)
      ),
    ])
      .pipe(untilDestroyed(this), take(1))
      .subscribe(result => {
        this.openedNewDialog = false;
        this.tripReasonControl.patchValue(result.data);
      });
    this.openedNewDialog = true;
    this.store.dispatch(new FragmentShowDialogAction(FragmentDialogTypeEnum.NEW_TRIP_REASON, undefined, { search }));
  }

  /**
   * Útvonal(ak) törlése
   */
  onDeleteTrips(): void {
    this.messageDialogService
      .open({
        id: null,
        enableCancel: true,
        cancelLabel: 'COMMON.ACTION.NO',
        confirmLabel: 'COMMON.ACTION.YES',
        text: 'COMMON.ARE_YOU_SURE_TO_DELETE',
        spriteClasses: ['common-sprite', 'common-ceruzay'],
      })
      .afterClosed()
      .subscribe(result => {
        if (result.result) {
          const selectTripEvent: SelectedTripActionModel = {
            event: SelectedTripsEventTypeEnum.SAVE,
            actionType: SelectedTripsActionTypeEnum.DELETE,
          };
          this.isDisabled = true;
          this.selectedTripEvent.emit(selectTripEvent);
        }
      });
  }
}
