<div [ngClass]="formFieldClass">
  <mat-form-field [floatLabel]="floatLabel" [hideRequiredMarker]="hideRequiredMarker">
    <mat-icon
      matPrefix
      *ngIf="hasPrefix && (selectedValue | hasPrefix: optionsConfig) as icon"
      class="mat-autocomplete-mat-prefix-icon mat-select-icon"
      [svgIcon]="icon"
    ></mat-icon>
    <mat-label *ngIf="floatLabel !== 'never'">{{ label || placeholder | transloco }}</mat-label>
    <mat-select
      [formControl]="insideFormControl"
      [placeholder]="placeholder || label | transloco"
      [panelClass]="classList"
      [required]="required"
      [attr.name]="name"
      [compareWith]="defaultCompareFn"
      (selectionChange)="onSelectionChange()"
    >
      <mat-select-trigger *ngIf="selectedValue?.id !== null">{{ selectedValue | displaySelectedValue: optionsConfig }}</mat-select-trigger>
      <mat-option>
        <!--disableScrollToActiveOnOptionsChanged should be set to true-->
        <ngx-mat-select-search
          #ngxMatSelectSearch
          [formControl]="searchFormControl"
          [placeholderLabel]="placeholder | transloco"
          [disableScrollToActiveOnOptionsChanged]="true"
          [searching]="loading$ | async"
          [noEntriesFoundLabel]="noEntriesFoundLabel$ | async | transloco"
        >
          <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option disabled="true" #notFoundMatOption *ngIf="firstLoading === false && (list$ | async)?.length === 0"
        ><b>{{ noEntriesFoundLabel$ | async | transloco }}</b></mat-option
      >
      <div
        class="infinty-scroll-wrapper"
        infiniteScroll
        [infiniteScrollDisabled]="infiniteScrollDisabled"
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        [fromRoot]="true"
        infiniteScrollContainer=".live-auto-complete-result-panel"
        (scrolled)="getNextBatch()"
        ><mat-option
          *ngFor="let item of list$ | async; let index = index"
          class="live-auto-complete-option"
          rrHoverState
          #hoverState="rrHoverState"
          [rrRawValue]="item"
          [value]="valueBind(item)"
          [disabled]="!(searchFormControl.value | isNil) && optionsConfig.optionDisabledBindFn(item, searchFormControl.value)"
          [ngClass]="(optionClassFn | isFunction) ? optionClassFn(item) : undefined"
        >
          <ng-container *ngIf="optionsConfig.optionModelIconFn(item) as optionModelIconConfig">
            <mat-icon
              class="mat-select-icon smaller"
              [svgIcon]="optionModelIconConfig.icon"
              [ngStyle]="{ 'margin-right.px': hasActionsMenu ? 8 : 16 }"
            ></mat-icon>
          </ng-container>
          <ng-container *ngIf="optionTplRef | isNil; else optionTpl"
            ><ngx-loadable
              *ngIf="!(optionsConfig?.lazyOptionElem | isNil); else simpleTextTpl"
              class="w-100"
              [module]="optionsConfig.lazyOptionElem?.name"
              [show]="true"
              (init)="lazyOptionElemInit($event, optionsConfig.lazyOptionElem, item)"
            ></ngx-loadable
            ><ng-template #simpleTextTpl
              ><span [innerHTML]="markSelectedText(optionsConfig.optionDisplayFn, item) | sanitizeHtml"></span
            ></ng-template>
          </ng-container>
          <ng-template #optionTpl>
            <span class="d-inline-block mt-3 mb-3"
              ><ng-container
                *ngTemplateOutlet="
                  optionTplRef;
                  context: { text: markSelectedText(optionsConfig.optionDisplayFn, item) | sanitizeHtml, item: item }
                "
              ></ng-container
            ></span>
          </ng-template>
          <span *ngIf="hasActionsMenu" [matTooltip]="(item | isEditable) ? '' : ('LIVE_AUTO_COMPLETE.NOT_EDITABLE_TOOLTIP' | transloco)"
            ><rr-hover-edit
              [hover]="hoverState.hover"
              [disableButton]="!(item | isEditable)"
              (clickButton)="onClickEditButton(item, valueBind(item))"
            ></rr-hover-edit
          ></span>
        </mat-option>
        <mat-option class="infinty-scroll-loader" *ngIf="(list$ | async)?.length > 0 && remoteConfig.hasNextDataPage" [disabled]="true">{{
          'LIVE_AUTO_COMPLETE.LOADING_ITEMS' | transloco
        }}</mat-option>
        <mat-option
          #newMatOptionButton
          class="new-button"
          rrPreventClick
          *ngIf="
            hasNewButton === true &&
            firstLoading === false &&
            ((list$ | async)?.length === 0 || remoteConfig.hasNextDataPage === false) &&
            (loading$ | async) === false
          "
          (click)="onClickNewButton()"
          [rrRawValue]="newButtonRawValue"
          value="fallback"
          ><mat-icon class="mat-select-icon smaller" svgIcon="plus-circle"></mat-icon
          ><span
            [innerHTML]="
              (optionsConfig?.newOptions?.label ? optionsConfig.newOptions.label : 'LIVE_AUTO_COMPLETE.NEW_BUTTON') +
                (searchFormControl?.value?.length > 0 ? '.WITH_SEARCH_TEXT' : '')
                | transloco: { searchText: searchFormControl.value }
                | sanitizeHtml
            "
          ></span>
        </mat-option>
      </div>
    </mat-select>
    <mat-error *ngIf="insideFormControl.invalid">
      <rr-validation-messages [errors]="insideFormControl?.errors" [messages]="validationMessages"></rr-validation-messages>
    </mat-error>
    <ng-content select="mat-hint"></ng-content>
    <mat-progress-spinner diameter="16" color="accent" mode="indeterminate" *ngIf="showProgressSpinner$ | async"></mat-progress-spinner>
  </mat-form-field>
  <ng-container *ngIf="hasActionsMenu">
    <button
      type="button"
      mat-icon-button
      class="action-menu-button"
      [matTooltip]="'LIVE_AUTO_COMPLETE.ACTIONS_TOOLTIP' | transloco"
      [matMenuTriggerFor]="actionsMenu"
      ><mat-icon svgIcon="dots-vertical"></mat-icon
    ></button>
    <mat-menu #actionsMenu="matMenu">
      <ng-template matMenuContent>
        <button type="button" mat-menu-item (click)="onClickNewButton()">
          <mat-icon svgIcon="plus"></mat-icon>
          <span>{{
            (optionsConfig?.newOptions?.label ? optionsConfig.newOptions.label : 'LIVE_AUTO_COMPLETE.NEW_BUTTON') | transloco
          }}</span>
        </button>
        <button
          type="button"
          mat-menu-item
          [disabled]="(insideFormControl.value | isNil) || (selectedValue | isEditable) === false"
          (click)="onClickEditButton()"
        >
          <mat-icon svgIcon="pencil"></mat-icon>
          <span>{{
            (optionsConfig?.editOptions?.label ? optionsConfig.editOptions.label : 'LIVE_AUTO_COMPLETE.EDIT_BUTTON') | transloco
          }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</div>
