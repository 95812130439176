import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@roadrecord/environment';
import { Store } from '@ngxs/store';
import { isNil } from '@roadrecord/type-guard';
import { PeriodContextStateSelectorsService } from '../state/selector/period-context-state-selectors.service';

@Injectable()
export class PeriodicStateContextInterceptor implements HttpInterceptor {
  constructor(private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>, private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = req;
    const isAssetsUrl = req.url.startsWith('./assets/') || req.url.startsWith('/assets/') || req.url.startsWith('assets/');
    if (
      isAssetsUrl === false &&
      (req.url.startsWith(environment.apiUrl) ||
        req.url.startsWith(environment.api2Url) ||
        req.url.startsWith(environment.api3Url) ||
        req.url.startsWith(environment.webadminApiUrl) ||
        req.url.startsWith(environment.webadminApi2Url) ||
        req.url.startsWith(`${environment.baseUrl}/batch/`)) &&
      !req.url.includes('period-context')
    ) {
      const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);
      if (!isNil(periodContext)) {
        const {
          year,
          month,
          vehicle: { id: vehicleId },
        } = periodContext;
        req = req.clone({
          headers: req.headers.set('User-State-Context', JSON.stringify({ vehicle_id: vehicleId, year, month })),
        });
      }
      //this.periodContextService.get()
      //{"year":2022,"month":2,"vehicle":{"id":344,"icon":"briefcase","type":0,"fuel_type":"95","plate_number":"GUF-311","is_self_employed":false}}
      //{ vehicle: vehicle_id, year: $event.yearMonth.format('YYYY'), month: $event.yearMonth.format('MM') }
    }

    return next.handle(req);
  }
}
