import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  AbstractEntityService,
  BatchRequestService,
  BatchResponseModel,
  checkModifiedFormPopup,
  commonHttpStreamErrorHandler,
  ENTITY_SERVICE_TOKEN,
  FieldHttpError,
  HandleErrorObject,
  handleErrorObjectGenerateDialogOptions,
  handleHttpError,
  HttpListResponseModel,
  MaybeHandleHttpError,
  RRHttpErrorResponse,
  throwHttpError,
} from '@roadrecord/utils';
import {
  deepEqual,
  hasDeletable,
  hasEditable,
  isDeletable,
  isEditable,
  isNotDeletable,
  isNotEditable,
  NoopFunction,
  NoopFunctionType,
  WINDOW,
} from '@roadrecord/common/common';
import { deletePopup, DeletePopupFnParameters, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { isEmpty, isFunction, isNil, isNotEmptyString, isNumeric, isObject, isString } from '@roadrecord/type-guard';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take } from 'rxjs/operators';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';
import { SearchFieldErrorStateMatcher } from '../error-matcher/search-field-error-state-matcher';
import { GridColumnModel } from '../model/grid-column.model';
import { IconColumnDataModel } from '../model/icon-column-data.model';
import { gridDefaultRightClickOption } from '../right-click/model/grid-default-right-click-option';
import { GridRightClickOptionModel } from '../right-click/model/grid-right-click-option.model';
import { GridChangeFilterValueAction } from '../state/action/grid-change-filter-value.action';
import { GridAddSelectionAction } from '../state/action/grid-add-selection.action';
import { GridInitAction } from '../state/action/grid-init.action';
import { GridLoadNextPageAction } from '../state/action/grid-load-next-page.action';
import { GridRevertStateAction } from '../state/action/grid-revert-state.action';
import { GridSortChangeAction } from '../state/action/grid-sort-change.action';
import { GridState } from '../state/grid.state';
import { GridStateModel } from '../state/model/grid-state.model';
import { GridsStateContainer } from '../state/model/grids-state-container.interface';
import { gridDatabaseCallback } from '../types/database-callback';
import { setIcon } from '../types/set-icon';
import { GridBottomSheetComponent } from './../grid-bottom-sheet/grid-bottom-sheet.component';
import { GridBottomSheetConfig } from './../grid-bottom-sheet/model/grid-bottom-sheet.config';
import { GridCloseBottomSheetAction } from './../state/action/grid-close-bottom-sheet.action';
import { GridOpenBottomSheetAction } from './../state/action/grid-open-bottom-sheet.action';
import { GridRemoveSelectionAction } from '../state/action/grid-remove-selection.action';
import { IsNotDeletableAndEditablePipe } from '../pipe/is-not-deletable-and-editable.pipe';
import { SecureDeleteDialogComponent } from '@roadrecord/secure-delete-dialog';
import { EditCellComponent } from '../edit-cell/edit-cell.component';
import { EditColumnsConfigs } from '../edit-cell/model/edit-columns.configs';
import { InitEditControlEvent } from '../edit-cell/model/init-edit-control.event';
import { GridDeleteElementsOption } from '../model/grid-delete-elements.option';
import { deepmerge } from '@roadrecord/deepmerge';
import { fadeInEnter, tableExpandPanel, zoomIn2XEnter } from '@roadrecord/animations';
import { GridHeaderGroupAction } from '../types/grid-header-group.action';
import { GridHeaderOtherAction } from '../types/grid-header-other.action';
import { generateDefaultGridHeaderOtherActions } from './generate-default-grid-header-other.actions';
import { generateDefaultGridHeaderGroupActions } from './generate-default-grid-header-group.actions';
import { GridPagingModel } from '../state/model/grid-paging.model';

export enum GridDefaultTexts {
  notEntitiesDataLabel = 'GRID.NOT_ENTITIES_DATA',
  notEntitiesDataLinkLabel = 'GRID.NOT_ENTITIES_DATA_LINK_TEXT',
}

export type NotEntitiesBackgroundImageType =
  | 'auto-partner'
  | 'autok'
  | 'hq-szines'
  | 'odometer'
  | 'partner-auto'
  | 'partnerek'
  | 'partnerimport'
  | 'tavolsagok'
  | 'toltoallomasok';

/**
 * Lehetseged scroll animacio: https://gist.github.com/andjosh/6764939
 */
const logScopeName = 'Grid';

export const trackByFn = <MODEL>(cb: (model: MODEL) => any) => (index: number, item: any): any => {
  // tslint:disable-next-line:no-invalid-this
  const id = cb(item);
  if (id === undefined) {
    return index;
  }
  return id;
};

@UntilDestroy()
@Component({
  selector: 'rr-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  animations: [fadeInEnter, tableExpandPanel, zoomIn2XEnter],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @Input() hasExpandGrid = false;
  @Input() hasSummaryRow = false;
  @Input() hasDetailsView = true;
  @Input() secureRemove = false;
  /**
   * fejlec es adatkotes beallitasok
   */
  @Input()
  columnDefinitions: GridColumnModel<any>[];
  /**
   * alap lapozo meret
   */
  @Input()
  pageSize = 100;
  /**
   * Hanyadik oldalon kezdodjon
   */
  // @Input()
  // pageIndex = 0;
  /**
   * lapozo meret beallitasi meretek
   *
   */
  // @Input()
  // pageSizeOptions = [25, 50, 100, 500, 1000];
  /**
   * A tablazat sor kijelolesert felelos
   * Ha nem adjuk meg akkor automatikusan letrejon
   */
  @Input()
  selection: SelectionModel<any>;
  /**
   * tobb kijeloles lehetosege
   *
   */
  @Input()
  multiSelection = true;
  /**
   * checkbox column legyen-e? (elso oszlop lesz)
   *
   */
  @Input()
  hasCheckboxColumn = false;
  /**
   * icon oszlop legyen-e? (elso oszlop ha nincs checkbox, ha van akkor masodik)
   *
   */
  @Input()
  hasIconColumn = false;
  /**
   * icon colum rendszer oszlop nev
   *
   */
  @Input()
  iconDisplayColumnName = 'icon';
  /**
   * icon column header name
   */
  @Input()
  iconDisplayColumnHeaderName = '';
  /**
   * Kartya title icon-ja
   */
  @Input()
  titleIcon: string;
  @Input()
  hasActionsColumn = false;
  @Input()
  hasCommonActionsColumn = false;
  @Input()
  actionsDisplayColumnName = 'actions';
  @Input()
  commonActionsDisplayColumnName = 'actions';
  /**
   * Kartya title
   * ha string tomb-t adunk at akkor auto forditunk es a tomb szerkezete a kovetkezo:
   * tomb[0] = SINGLE_TRANSLATE_KEY
   * tomb[1] = PLURAL_TRANSLATE_KEY
   */
  @Input()
  titleText: string | string[];
  @Input()
  disableTitleTranslate = false;
  /**
   * icon oszlop callback
   */
  @Input()
  setIconFn: setIcon;
  /**
   * icon oszlop property name
   *
   */
  @Input()
  setIconPropertyName = 'icon';
  /**
   * szabadszavas szuro-ben a billentyu leutesek kozott mennyi ido telhet el
   *
   */
  @Input()
  filterDelay = 350;
  /**
   * Amennyiben szeretnek a header es a tablazat koze custom teruletet beszurni,
   * akkor egy template referencia atadasaval meg is tehetjuk
   */
  @Input()
  customAreaTemplateRef: TemplateRef<any>;
  /**
   * lehetoseg van a getAll fuggveny hivas kicserelesere
   */
  @Input()
  databaseCallback: gridDatabaseCallback;
  /**
   * ha be van allitva es nincs feliratkozo az editRow output-ra akkor
   * dupla click-re vagy editre automatikusan meg fogja nyitni az entitast szerkesztesre
   */
  @Input()
  autoOpenEditRoute = true;
  /**
   * ki lehet teljesen kapcsolni a sor kattintas detectet, de selection tovabbra is van!
   */
  @Input()
  disableRowClick = false;
  /**
   * ki lehet teljes mertekben kapcsolni a soron a kattintast erzekeleset
   */
  @Input()
  fullDisableRowClick = false;
  /**
   * ha nincs rogzitett adat akkor kirakjunk-e szoveget link-vel egyutt
   * (ha az elso lekerdezesnel 0 entitast kapunk vissza es a keresobe sem irtak meg semmit, akkor nincs rogzitett
   * entitas allapotnak vesszuk)
   */
  @Input()
  hasNotEntitiesDataLabel = true;
  @Input()
  hasNotEntitiesDataLabelLinkHide = false;
  /**
   * ha nem az auto new route-t szeretnenk, mert peldaul egy popup-t kene kinyitni
   * akkor a callback-vel tudjuk kezelni
   */
  @Input()
  hasNotEntitiesDataLabelLinkCallback: NoopFunctionType;
  /**
   * nincs adat szovege
   */
  @Input()
  notEntitiesDataLabel: string = GridDefaultTexts.notEntitiesDataLabel;
  /**
   * nincs adat link szovege
   */
  @Input()
  notEntitiesDataLinkLabel: string = GridDefaultTexts.notEntitiesDataLinkLabel;
  /**
   * Nincs entity a listaban, akkor hasNotEntitiesDataLabel ala kirakhato custom area
   */
  @Input()
  hasNotEntitiesDataCustomArea: TemplateRef<any>;
  @Input()
  defaultSortDirection: SortDirection = 'asc';
  /**
   * alap rendezesi irany
   */
  @Input()
  defaultSort: string;
  /**
   * column definition tartalmazz radio button-t
   */
  @Input()
  hasRadioButton = false;
  /**
   * grid sor kijeloles teljes kikapcsolasa
   */
  @Input()
  disableSelection = false;
  /**
   * grid title alatti terulet jobb oldala amennyiben van filter ha nincs akkor a teljes terulet
   */
  @Input()
  customRightAreaTpl: TemplateRef<any>;
  @Input() actionsRowsTemplateRef: TemplateRef<unknown> | null;
  /**
   * material datasource-t taroljuk
   */
  dataSource = new MatTableDataSource();
  /**
   * a lekerdezes count-ja
   * paginatornak kell
   */
  resultsLength = 0;
  /**
   * flag ami jelzi hogy lekerdezes van folyamatban
   */
  isLoadingResults = false;
  /**
   * tenylegesen ez alapjan jelenitjuk meg az oszlopokat
   */
  displayColumns: string[];
  footerDisplayColumns: string[];
  /**
   * bekapcsolt rendezheto oszlopok megnevezese
   */
  enabledSortColumns: string[] = [];
  /**
   * icon oszlop adatai
   */
  iconColumnDatas: IconColumnDataModel[];
  /**
   * szabad szavas szuro form control
   */
  filterFormControl: FormControl;
  searchFieldErrorMatcher = new SearchFieldErrorStateMatcher();
  @ViewChild('sortForDataSource', { static: true })
  sort: MatSort;
  @ViewChild('filter', { static: true })
  filter: ElementRef;
  /**
   * Expandable grid esetén sorra kattintás esetén jelzunk kifele
   */
  @Output()
  expandRowEvent = new EventEmitter<{ element: unknown; index: number }>();
  /**
   *  Egy adott sorra kattintás esetén jelzünk kifelé
   */
  @Output()
  rowClickEvent = new EventEmitter<{ element: unknown; index: number }>();
  /**
   * sor szerkesztes eseten jelzunk kifele
   */
  @Output()
  editRow = new EventEmitter<{ element: unknown; index: number }>();
  /**
   * sor torlese eseten jelzunk kifele
   */
  @Output()
  deleteRow = new EventEmitter<any[]>();
  @Output()
  deletedRow = new EventEmitter<any[]>();
  /**
   * minden adatlekeres eseten jelzunk kifele
   */
  @Output()
  remoteRefresh = new EventEmitter<HttpListResponseModel<any>>();
  /**
   * az elso lekerdes eseten jelzunk
   */
  @Output()
  firstResponse = new EventEmitter<HttpListResponseModel<any>>();
  @Output()
  afterLoaded = new EventEmitter<HttpListResponseModel<any>>();
  /**
   * ez a jelenlegit adja vissza
   */
  @Output()
  selectionChange = new EventEmitter<any[]>();
  /**
   * az eredeti material selection eventet adja vissza
   */
  @Output()
  selectionChanges = new EventEmitter<SelectionChange<any>>();
  /**
   * firstResponse output-hoz seged flag
   */
  firstResponseFlag = true;
  /**
   * ngFor optimizing
   */
  trackByModel = trackByFn(model => this.database.getModelIdValue(model));
  @Input()
  gridId: string;
  /**
   * radio tipusu oszlopok configjat taroljuk benne mivel kulon kell kezelni a render-nel
   */
  radioColumns: GridColumnModel<any>[] = [];
  originalDataSoureData: any[];
  @Output()
  changedDataSourceData: EventEmitter<boolean> = new EventEmitter<boolean>();
  radioColumnsState: { intermidate: boolean; all: boolean; hasDisabledColumn: boolean }[];
  @Input()
  readonly remoteResponseResultsMapper: (map: any[]) => any[];
  /**
   * http -n kuldott utolso adatot taroljuk benne, nem osszekeverendo a originalDataSoureData -vel ami lehet akar mar szurt
   * allpot is a remoteResponseResultsMapper altal
   */
  originalHttpResponse: HttpListResponseModel<any>;
  originalHttpResponseIconColumnDatas: IconColumnDataModel[];
  @Input()
  readonly checkModified = false;
  /**
   * amikor a component megjelenik akkor induljon-e auto lekerdezes
   */
  @Input()
  firstAutoLoad = true;
  @Input() hasRightClickContextDefaultGridOptions = true;
  @Input()
  rightClickContextMenuOption: GridRightClickOptionModel<any>;
  @ViewChildren(ContextMenuComponent) rightClickMenuComponent: QueryList<ContextMenuComponent>;
  rightClickContextMenuClass: string;
  @Input() rightClickContextMenuDisabled = false;
  @Output() changeDataSourceData = new EventEmitter<{ add: any[]; revert: any[] }>();
  /**
   * selection kezelese request eseten
   */
  // private getLastSelectedFromCurrentSelection(lastSelected: any[], data): any {
  //   lastSelected = this.selection.selected.reduce((newArray, currentItem) => {
  //     const found = data.results.find(
  //       remoteData => this.database.getModelIdValue(remoteData) === this.database.getModelIdValue(currentItem)
  //     );
  //     return found === undefined ? newArray : newArray.concat(found);
  //   }, []);
  //   return lastSelected;
  // }
  @Input() rowSelectIsNotDeletableAndEditableTranslateKey = 'GRID.IS_NOT_DELETABLE_AND_EDITABLE';
  @Input() filterValidatorMinLength = 3;
  @ViewChild(SecureDeleteDialogComponent) secureDeleteDialogComponent: SecureDeleteDialogComponent;
  showSecureRemoveDialog = false;
  secureDialogFullName: string;
  @Input() secureRemoveDialogFullNameKey: (item: any) => string;
  // edit row feature variables
  @Input() editColumns: string[];
  @Input() editColumnsConfig: EditColumnsConfigs;
  @Input() hasActionRemove = false;
  @Input() hasActionRemoveDisableCb: null | ((entity: any) => boolean) = null;
  @Input() hasCommonEditActionDisableCb: null | ((entity: any) => boolean) = null;
  @Input() hasActionRemoveDatabaseCb: null | ((i: number, row: any, grid: GridComponent) => void) = null;
  editRowIndex: number;
  editCellRowIndex: number;
  @ViewChildren(EditCellComponent) editCellComponents: QueryList<EditCellComponent>;
  @Output() initEditControl = new EventEmitter<InitEditControlEvent<unknown>>();
  @Output() editControls = new EventEmitter<InitEditControlEvent<unknown>>();
  @Output() finishRowEdit = new EventEmitter<void>();
  @Input() editRowNewModel: any;
  @Input()
  hasMultiLineEdit = false;
  editFormGroup: FormGroup;
  multiLineEditFormArray: FormArray;
  @Input() secureRemoveDialogTranslateDialogTitle: string;
  @Input() secureRemoveDialogTranslateLastAndFirstNameLabel: string;
  @Input() secureRemoveDialogTranslateFullNameLabel: string;
  @Input() secureRemoveDialogTranslateInputLabel: any;
  @Input() secureRemoveDialogTranslateInputPlaceholder: any;
  @Input() secureRemoveDialogTranslateInputErrorMismatch: any;
  @Input() secureRemoveDialogTranslateButtonTooltip: any;
  disableCheckboxColumn = false;
  @Input() gridFilterHint: string;
  @Input() deleteElementsOptions: GridDeleteElementsOption<any>;
  /**
   * Type = lehetseges fajlnevek az images/grid-background -ban
   */
  @Input() notEntitiesBackgroundImage: NotEntitiesBackgroundImageType;
  @Output() createClickRowEdit = new EventEmitter<boolean>();
  @Output() updateClickRowEdit = new EventEmitter<boolean>();
  @Input() editDataRouteCb: (id: any) => string;
  @Input() editDataRouteQueryParamCb: (id: any, params: Params) => void;
  /**
   * Specialis esetekben az osszes input-t kinyitjuk, viszont ilyenkor nincs sor muvelet!
   */
  @Input() openAllEditInput = false;
  @Input() editColumnUpdateActionAutoAddId = true;
  /**
   * Kodbol vezerelt flag, hogy megjelenjen-e a filter, viszont ha hasFilter === false akkor ezt sem vesszuk figyelembe
   * # https://roadrecord.myjetbrains.com/youtrack/issue/RROHU-2585
   */
  showFilter = true;
  checkShowFilter = true;
  // @ViewChildren('tableWrapperElement') private tableWrapperElement: QueryList<ElementRef<HTMLDivElement>>;
  /**
   * Edit cell hasznalja
   */
  remoteControlsErrors: { [key: string]: { error: string; lastValue: string } };
  private runRefresh = false;
  private _changedDataSourceData: boolean;
  private runGridStateRevert = false;
  private dataStreamSubscription: Subscription;
  private disableRadioColumnCheckboxSelectTextMatSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private disableSaveScrollState = false;
  private disableNextSelectionClear = false;
  private secureDialogSelectedEntity: any;
  private oldEnabledSortColumns: string[];
  private disableFloatingButton$ = new BehaviorSubject(false);

  @Input() headerGroupActionDisabledLimit = 1;
  @Input() headerGroupActions: GridHeaderGroupAction<any>[];
  @Input() headerOtherActions: GridHeaderOtherAction<any>[];
  @Input() headerSingleButtonAction: GridHeaderGroupAction<any>;

  @Input() addEditRowHeaderOtherAction = false;
  @Input() deletePopupTextTranslateKey: string;
  fullDisableSelection = true;

  /**
   * ng-template alapu referencia expand row esetén
   */
  @Input() expandRowTemplateRef?: TemplateRef<any>;

  /**
   * ng-template alapu referenciak
   */
  @Input() cellsTemplateRef?: TemplateRef<any>[];

  /**
   * ng-template alapu referenciak inline edit esetén
   */
  @Input() editCellsTemplateRef?: TemplateRef<any>[];
  /**
   * ng-template alapu referenciak inline edit upload file esetén
   */
  @Input() uploadFileCellsTemplateRef?: TemplateRef<any>[];
  /**
   * ng-template alapu referenciak summary sor esetén
   */
  @Input() summaryCellsTemplateRef?: TemplateRef<unknown>[];

  expandedRowElements: any[] = [];

  hasNexPage = true;

  allEditableRowsRendered = false;
  @Output() allEditableRowsAndControlRendered = new EventEmitter<void>();

  @Input() rowCursorIsPointer = false;
  @Input() enablePagingStore = true;

  constructor(
    @Inject(ENTITY_SERVICE_TOKEN) readonly database: AbstractEntityService<HttpListResponseModel<any>, any>,
    readonly router: Router,
    readonly route: ActivatedRoute,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    readonly cdr: ChangeDetectorRef,
    private store: Store,
    private actions$: Actions,
    private batchRequestService: BatchRequestService,
    private contextMenuService: ContextMenuService,
    @Inject(WINDOW) private window: Window,
    private ngZone: NgZone,
    private renderer2: Renderer2,
    private matBottomSheet: MatBottomSheet,
    private messageDialogService: MessageDialogService
  ) {
    this.defaultSort = this.database.entityDefaultOrder;

    this.initStateActions();
    this.handleRowEditFinish();
  }

  /**
   * edit row feature
   */
  @HostBinding('class.editing-row') get editingRow() {
    return this.editRowIndex !== undefined;
  }

  /**
   * szabadszavas szuro megjelenjen-e
   *
   */
  private _hasFilter = false;

  get hasFilter(): boolean {
    return this._hasFilter;
  }

  @Input()
  set hasFilter(value: boolean) {
    this._hasFilter = value;
    if (value === false) {
      this.showFilter = false;
    }
  }

  private _gridFilterLabel = 'GRID.FILTER';

  get gridFilterLabel(): string {
    return this._gridFilterLabel;
  }

  @Input()
  set gridFilterLabel(value: string) {
    if (isString(value) && value.length > 0) {
      this._gridFilterLabel = value;
    } else {
      this._gridFilterLabel = 'GRID.FILTER';
    }
  }

  private _gridFilterPlaceholder = 'GRID.FILTER';

  get gridFilterPlaceholder(): string {
    return this._gridFilterPlaceholder;
  }

  @Input()
  set gridFilterPlaceholder(value: string) {
    if (isString(value) && value.length > 0) {
      this._gridFilterPlaceholder = value;
    } else {
      this._gridFilterPlaceholder = 'GRID.FILTER';
    }
  }

  /**
   * refresh stream
   */
  private _refresh$ = new Subject<void>();

  get refresh$(): Observable<void> {
    return this._refresh$.asObservable();
  }

  /**
   * a table sor kattintast kezeljuk
   */
  clickRow(element: any, $event, index: number): boolean {
    if (this.editRowIndex === undefined) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    return false;
  }

  ngOnInit(): void {
    // TODO ez itt tuti jo? a disabled resze furi
    if (this.hasRightClickContextDefaultGridOptions === true || this.rightClickContextMenuDisabled === true) {
      this.rightClickContextMenuOption = gridDefaultRightClickOption<any>(this);
    }
    if (this.gridId === undefined) {
      this.gridId = this.router.url.split('?')[0];
      this.rightClickContextMenuClass = `grid${this.gridId.replace(new RegExp('/', 'g'), '-')}`;
    }

    this.initFilterControl();
    if (!this.disableSelection) {
      this.fullDisableSelection = false;
      this.initSelectionInput();
    }
    this.initState();
    this.checkHasRadioButton();
    this.checkRequiredInputs();
    this.initDisplayColumns();
    this.initHasCheckboxColumnInput();
    this.initHasActionsColumnInput();
    this.initHasCommonActionsColumnInput();
    this.initHasSummaryRow();
    this.initHasIconColumnInput();

    this.initDatabaseAndSubscribeTableStreams();

    if (isNil(this.headerGroupActions)) {
      this.headerGroupActions = generateDefaultGridHeaderGroupActions<any>(this);
    }
    if (isNil(this.headerOtherActions)) {
      this.headerOtherActions = generateDefaultGridHeaderOtherActions<any>(this);
    }
  }

  /**
   * ezzel vizsgaljuk hogy az osszes sor ki van-e valasztva
   */
  isAllSelected(): boolean {
    if (this.multiSelection === false) {
      return this.selection.selected.length === 1 && this.selection.selected[0] !== undefined;
    }

    const numSelected = this.selection.selected.filter(data => isObject(data) && (isEditable(data) || isDeletable(data))).length;
    const numRows = this.dataSource.data.length;
    const notEditableAndDeletableCount = this.dataSource.data.filter(this.arrayFilterIsNotEditableAndNotDeleteable()).length;
    return numSelected === numRows - notEditableAndDeletableCount;
  }

  /**
   * a osszes kijelolo checkbox kezelese (toggle = valtas)
   */
  masterToggle(): void {
    // this.runRefresh = true;
    if (this.isAllSelected()) {
      const isNotEdiableAndNotDeletable = this.selection.selected.filter(this.arrayFilterIsNotEditableAndNotDeleteable());
      this.selection.clear();
      if (isNotEdiableAndNotDeletable.length > 0) {
        this.selection.select(...isNotEdiableAndNotDeletable);
      }
    } else {
      if (this.multiSelection === true) {
        this.selection.select(...this.dataSource.data.filter(data => !(isNotEditable(data) && isNotDeletable(data))));
      }
    }
    // timer(0).subscribe(() => (this.runRefresh = false));
  }

  refresh(): void {
    this.runRefresh = true;
    this._refresh$.next();
  }

  deleteSelectedElements({
    hasRefresh = true,
    successDelete,
    beforeDelete,
    deleteError,
    deleteElements,
    options = {},
  }: {
    hasRefresh?: /*required*/ boolean;
    successDelete?: (responses?: any[]) => Promise<void>;
    beforeDelete?: () => void;
    deleteError?: (error) => void;
    deleteElements?: any[];
    options?: GridDeleteElementsOption<any>;
  }): void {
    this.matSnackBar.dismiss();
    options = deepmerge(this.deleteElementsOptions, options);
    delete this.secureDialogSelectedEntity;

    if (this.secureRemove) {
      this.showSecureRemoveDialog = true;
      this.secureDialogFullName = this.secureRemoveDialogFullNameKey(deleteElements[0]);
      this.secureDialogSelectedEntity = deleteElements[0];
      this.cdr.markForCheck();
      timer(0).subscribe(() => {
        this.secureDeleteDialogComponent.openDelete();
      });
    } else {
      const deletePopupParameters: DeletePopupFnParameters = {
        matSnackBar: this.matSnackBar,
        translocoService: this.translocoService,
        messageDialogService: this.messageDialogService,
        yesCallback: (finishCallback: () => void, result) => {
          const allDeletedRows = deleteElements ? deleteElements : [...this.selection.selected];
          if (!isNil(beforeDelete)) {
            beforeDelete();
          }
          this.isLoadingResults = true;
          this.cdr.detectChanges();
          this.deleteRow.emit(allDeletedRows);

          this.batchRequestService.start();
          // nem torolhetok kiszurese
          const hasNotDeletableRow = allDeletedRows
            .filter(selected => isNotDeletable(selected))
            .map(selected => ({
              state: this.translocoService.translate('GRID.DELETE.UNSUCCESSFUL'),
              title: this.database.getToString(selected),
            }));

          // torolhetok felvetele batchbe
          const deleteRows = [];
          allDeletedRows
            .filter(selected => isDeletable(selected))
            .forEach(selected => {
              const deleteRowId = this.database.getModelIdValue(selected);
              deleteRows.push(selected);
              this.batchRequestService.add(
                isFunction(options.removeBatchCb) ? options.removeBatchCb(deleteRowId, result) : this.database.removeBatch(deleteRowId)
              );
            });

          const maxCount = this.batchRequestService.requestCount;
          timer(0).subscribe(() =>
            this.matSnackBar.open(this.translocoService.translate('GRID.DELETE.START', { length: maxCount }), undefined, {
              duration: 60 * 5 * 1000,
            })
          );
          // Batch request inditasa
          this.batchRequestService.end().subscribe(
            responses => {
              const finish = () => {
                this.deletedRow.emit(allDeletedRows);
                if (hasRefresh) {
                  this.refresh();
                }
                this.cdr.detectChanges();

                finishCallback();
                if (!isNil(successDelete)) {
                  successDelete(responses).then(NoopFunction);
                }
              };

              if (responses.length === 1 && allDeletedRows.length === 1) {
                // ha csak 1 valasz van akkor snackbarral kommunikalunk
                let snackBarText: string;
                const response: BatchResponseModel<any> = responses[0];
                if ([200, 204].indexOf(responses[0].code) > -1) {
                  snackBarText = this.translocoService.translate('GRID.DELETE.SUCCESSFUL');
                  this.matSnackBar.open(snackBarText);
                  finish();
                  return;
                } else {
                  const error = throwHttpError(
                    new RRHttpErrorResponse({
                      error: response.body,
                      statusText: response.body,
                      status: response.code,
                      url: '',
                    })
                  ) as RRHttpErrorResponse;
                  handleHttpError(
                    error,
                    this.window,
                    this.translocoService,
                    this.messageDialogService,
                    this.router,
                    (_error: RRHttpErrorResponse, translocoService: TranslocoService) =>
                      HandleErrorObject.handleErrorObject(_error).subscribe(() => finish())
                  );
                  this.matSnackBar.dismiss();
                  return;
                }
              }
              /**
               * rendezeshez
               * https://stackoverflow.com/a/38641281
               */
              const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

              const successRows = responses
                .map((response, index) => {
                  let state: string;
                  if ([200, 204].indexOf(response.code) > -1) {
                    state = this.translocoService.translate('GRID.DELETE.SUCCESSFUL');
                    return { state, title: this.database.getToString(deleteRows[index]) };
                  }
                  return null;
                })
                .filter(v => v !== null)
                .sort(collator.compare as any);

              const errorRows = hasNotDeletableRow
                .concat(
                  responses.map((response, index) => {
                    let state: string | any;
                    if ([200, 204].indexOf(response.code) === -1) {
                      try {
                        const body: { detail?: string } = response.body;
                        state = !isNil(body) && !isNil(body.detail) ? body : this.translocoService.translate('GRID.DELETE.UNKNOWN_ERROR');
                      } catch (e) {
                        state = this.translocoService.translate('GRID.DELETE.UNSUCCESSFUL');
                      }
                      return {
                        state,
                        title: this.database.getToString(deleteRows[index]) || this.translocoService.translate('COMMON.UNKNOWN'),
                      };
                    }
                    return null;
                  })
                )
                .filter(v => v !== null)
                .sort(collator.compare as any);
              const errorTable =
                errorRows.length > 0
                  ? `<table><caption>${
                      successRows.length > 0
                        ? `${this.translocoService.translate('GRID.DELETE.ITEMS_DELETED', { items: successRows.length })}<br/>`
                        : ''
                    }${this.translocoService.translate('GRID.DELETE.ITEMS_NOT_DELETED')}</caption>
                    <thead><th>${this.translocoService.translate('GRID.DELETE.ITEM.NAME')}</th><th>${this.translocoService.translate(
                      'GRID.DELETE.ITEM.STATE'
                    )}</th></thead><tbody>
                    ${errorRows
                      .map(
                        row =>
                          `<tr><td>${row.title}</td><td>${
                            handleErrorObjectGenerateDialogOptions(
                              isNumeric((row.state as any).error_type) ? { error: row.state } : ({ error: { detail: row.state } } as any),
                              undefined,
                              ['br']
                            ).text
                          }</td></tr>`
                      )
                      .join('')}</tbody></table>`
                  : '';

              this.matSnackBar.dismiss();
              if (errorTable.length === 0) {
                this.matSnackBar.open(this.translocoService.translate('GRID.DELETE.ITEMS_DELETED', { items: successRows.length }));
              } else {
                this.messageDialogService.open(
                  {
                    id: null,
                    type: errorTable.length > 0 ? MessageDialogTypeEnum.WARNING : MessageDialogTypeEnum.INFORMATION,
                    text: errorTable,
                    htmlMode: true,
                    translateText: false,
                  },
                  {
                    panelClass: ['with-table-content', 'has-scroll-table', 'responsive'],
                  }
                );
              }

              finish();
            },
            error =>
              MaybeHandleHttpError.maybeHandleHttpError(error, () => {
                this.isLoadingResults = false;
                this.matSnackBar.dismiss();
                this.cdr.detectChanges();
                if (isFunction(deleteError)) {
                  deleteError(error);
                }
              })
          );
        },
        noCallback: undefined,
        successText: 'COMMON.SUCCESS_DELETE',
      };
      if (!isNil(options.slideToggleText) && isNotEmptyString(options.slideToggleText)) {
        deletePopupParameters.slideToggleLabel = options.slideToggleText;
      }

      if (!isNil(this.deletePopupTextTranslateKey) && isNotEmptyString(this.deletePopupTextTranslateKey)) {
        deletePopupParameters.text = this.deletePopupTextTranslateKey;
      }
      deletePopup(deletePopupParameters);
    }
  }

  onHasNotEntitiesDataLabelLinkCallback(): void {
    this.hasNotEntitiesDataLabelLinkCallback();
  }

  dispatchGridInitAction(): void {
    this.store.dispatch(
      new GridInitAction(
        this.gridId,
        this.defaultSort,
        this.defaultSortDirection,
        this.pageSize,
        // this.pageIndex,
        this._hasFilter ? this.filterFormControl.value : undefined,
        this.disableSelection ? undefined : this.selection.selected,
        this.disableSelection ? undefined : this.selection.isMultipleSelection()
      )
    );
  }

  handleRowClick({ element: selected, index }: { element: unknown; index: number }, shouldSelect = true): void {
    if (!this.disableSelection && shouldSelect) {
      this.selection.select(selected);
    }
    if (isNotEditable(selected)) {
      this.snackbarMessageEditableOrDeletableOrTogether(selected, null);
      return;
    }

    if (this.editRow.observers.length === 0) {
      this.editData(selected);
    } else {
      this.editRow.emit({ element: selected, index });
    }
  }

  onClickCommonEditRow(row: any) {
    this.editData(row);
  }

  resetChangedDetect(): void {
    this.originalDataSoureData = tsDeepcopy(this.dataSource.data);
    this._changedDataSourceData = false;
    this.changedDataSourceData.emit(false);
  }

  /**
   * radio button click kezelese, mivel minden radio button kulon group-ba kerul
   * igy nekunk kell a group feladatait ellatni
   */
  clickRadioButton(column: GridColumnModel<any>, element: any): void {
    if (this.checkRadioIsDisabled(column, element)) {
      return;
    }

    if (element[column.radioName] !== column.radioValue) {
      element[column.radioName] = column.radioValue;
    }

    this.recalculateRadioButtonsState();
    this.checkChangedDataSourceData([element]);
  }

  /**
   * radio button header checkbox hasznalja hogy bejelolje az osszes elemet az oszlopban
   */
  toggleRadioColumn(column: GridColumnModel<any>): void {
    if (this.disableRadioColumnCheckboxSelectTextMatSnackBarRef !== undefined) {
      this.disableRadioColumnCheckboxSelectTextMatSnackBarRef.dismiss();
      delete this.disableRadioColumnCheckboxSelectTextMatSnackBarRef;
    }
    // data rows deep copy
    this.dataSource.data = tsDeepcopy(this.dataSource.data).map(data => {
      if (!this.checkRadioIsDisabled(column, data)) {
        data[column.radioName] = column.radioValue;
      }
      return data;
    });
    // changed emit
    this.checkChangedDataSourceData(this.dataSource.data);
    // radio button header checkboxies recalculation values
    this.recalculateRadioButtonsState();
    if (isString(column.disableRadioColumnCheckboxSelectText) && this.radioColumnsState[column.radioValue].hasDisabledColumn) {
      this.disableRadioColumnCheckboxSelectTextMatSnackBarRef = this.matSnackBar.open(
        this.translocoService.translate(column.disableRadioColumnCheckboxSelectText)
      );
    }
    this.cdr.detectChanges();
  }

  revertGridState(): void {
    // filter kivetelevel mindent vissza allitani kezzel , filter meg majd a state revert allitja vissza
    this.runGridStateRevert = true;
    const previousState = this.store.selectSnapshot<GridStateModel>(
      states => (states.grids as GridsStateContainer).previousContainer[this.gridId]
    );
    // this.pageIndex = previousState.page.pageIndex;
    // this.paginator.pageSize = previousState.page.pageSize;
    this.sort.active = previousState.sort.active;
    this.sort.direction = previousState.sort.direction;
    this.filterFormControl.patchValue(previousState.filter, { emitEvent: false });
    this.store.dispatch(new GridRevertStateAction(this.gridId));
  }

  ngOnDestroy(): void {
    if (!isNil(this.dataStreamSubscription)) {
      this.dataStreamSubscription.unsubscribe();
    }
    this.maybeCloseRightClickContextMenu();
    // hogyha nyitva van a submenu is
    this.maybeCloseRightClickContextMenu();
  }

  resetFilterForm(): void {
    this.filterFormControl.patchValue('', { emitEvent: false });
    this.store.dispatch(new GridChangeFilterValueAction(this.gridId, ''));
  }

  // ngAfterViewInit(): void {
  //   fromEvent(this.tableWrapperElement.first.nativeElement, 'scroll')
  //     .pipe(
  //       untilDestroyed(this),
  //       debounceTime(100),
  //       filter(() => !this.disableSaveScrollState)
  //     )
  //     .subscribe((event: any) => this.store.dispatch(new GridSaveLastScrollPositionAction(this.gridId, event.target.scrollTop)));
  // }

  ngAfterViewChecked() {
    if (
      this.hasMultiLineEdit === true &&
      !isNil(this.editColumnsConfig) &&
      !isNil(this.editCellComponents) &&
      !isNil(this.multiLineEditFormArray) &&
      this.dataSource.data.length === this.multiLineEditFormArray.length &&
      this.allEditableRowsRendered === false
    ) {
      this.allEditableRowsRendered = true;
      this.allEditableRowsAndControlRendered.emit();
      this.cdr.detectChanges();
    }
  }

  onClickRow($event: MouseEvent, row: any, index: number) {
    this.rowClickEvent.emit({ element: row, index: index });

    this.editCellRowIndex = index;

    if (new IsNotDeletableAndEditablePipe().transform(row)) {
      // ha nem szerkesztheto vagy nem torolheto akkor nincs click detect
      if (this.editRowIndex === undefined) {
        $event.stopPropagation();
        $event.preventDefault();
      }
      return false;
    }

    if (this.fullDisableRowClick === false) {
      if (this.disableRowClick === false) {
        this.clickRow(row, $event, index);
      } else if (!this.disableSelection) {
        this.selection.toggle(row);
      }
    }

    if (this.hasExpandGrid === true) {
      this.expandRowEvent.emit({ element: row, index: index });
    }
  }

  isAllSelectedNotIsNotEditableAndNotDeletables() {
    return this.selection.selected.filter(this.arrayFilterIsNotEditableAndNotDeleteable()).length === this.selection.selected.length;
  }

  onSecureRemoveUser() {
    this.deleteRow.emit([this.secureDialogSelectedEntity]);
    this.isLoadingResults = true;
    this.database.remove(this.database.getModelIdValue(this.secureDialogSelectedEntity)).subscribe(
      () => {
        this.matSnackBar.open(this.translocoService.translate('GRID.DELETE.SUCCESSFUL'));
        this.refresh();
      },
      commonHttpStreamErrorHandler(() => (this.isLoadingResults = false))
    );
  }

  /**
   * edit row feature
   */
  onClickRowEdit(index: number, row: any) {
    this.editRowIndex = index;
    this.disableSelection = true;
    this.oldEnabledSortColumns = [...this.enabledSortColumns];
    this.enabledSortColumns = [];
    this.disableCheckboxColumn = true;
    this.rightClickContextMenuDisabled = true;
    this.disableFloatingButton$.next(true);
    if (!isNil(this.filterFormControl)) {
      this.filterFormControl.disable();
    }
    // this.paginator.disabled = true;

    this.cdr.markForCheck();
  }

  /**
   * edit row feature
   */
  onClickRowEditCancel(i: number, row: any) {
    // TODO content change detect!
    delete this.editRowIndex;

    if (this.dataSource.data.length === 1 && isNil(this.database.getModelIdValue(row))) {
      // ha egy uj elem volt csak a gridben, akkor cancel utan mar nem lesz elem igy resetelni kell ezeket is
      this.resultsLength = 0;
    }
    if (row === undefined || isNil(this.database.getModelIdValue(row))) {
      // ha uj sor volt, akkor torolni kell
      const newRows = tsDeepcopy(this.dataSource.data);
      newRows.splice(i, 1);
      this.dataSource.data = newRows;
    }

    this.finishRowEdit.emit();
  }

  /**
   * edit row feature
   */
  onClickRowRemove(i: any, row: any) {
    if (isFunction(this.hasActionRemoveDatabaseCb)) {
      this.hasActionRemoveDatabaseCb(i, row, this);
    } else {
      this.deleteSelectedElements({
        successDelete: responses => {
          if ([200, 204].indexOf(responses[0].code) > -1) {
            delete this.editRowIndex;
            this.finishRowEdit.emit();
            this.cdr.markForCheck();
          }
          return Promise.resolve();
        },
        deleteElements: [row],
      });
    }
  }

  /**
   * edit row feature
   */
  onClickNewRow() {
    const newRows = tsDeepcopy(Array.isArray(this.dataSource.data) ? this.dataSource.data : []);
    newRows.push({ ...this.editRowNewModel });
    this.dataSource.data = newRows;
    //  open edit mode
    this.editRowIndex = newRows.length - 1;
    this.disableSelection = true;
    this.oldEnabledSortColumns = [...this.enabledSortColumns];
    this.enabledSortColumns = [];
    this.disableCheckboxColumn = true;
    this.rightClickContextMenuDisabled = true;
    if (newRows.length === 1) {
      this.resultsLength = 1;
    }
    this.disableFloatingButton$.next(true);
    // this.paginator.disabled = true;
    this.filterFormControl.disable();
  }

  /**
   * edit row feature
   */
  onClickRowEditSave(i: number, row: any) {
    const cmps = this.editCellComponents.filter(cmp => cmp.editMode === true);
    if (cmps.find(cmp => !isNil(cmp.control) && cmp.control.invalid) === undefined) {
      let saveModel = this.editColumns.reduce((accum, key, index) => {
        if (!isNil(cmps[index].control) && cmps[index].control.enabled) {
          accum[key] = cmps[index].control.value;
        }
        return accum;
      }, {});

      const finishCb = () => {
        delete this.editRowIndex;
        this.finishRowEdit.emit();
      };
      const editModelId = this.database.getModelIdValue(row);
      const isNew = isNil(editModelId);
      if (!isNew) {
        // valtozas check-olasa
        const originalModel = this.editColumns.reduce((accum, key, index) => {
          if (!isNil(cmps[index].control)) {
            accum[key] = row[key];
          }
          return accum;
        }, {});
        if (deepEqual(saveModel, originalModel)) {
          this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.NO_CHANGES'));
          this.updateClickRowEdit.emit(true);
          finishCb();
          return;
        }
      }
      this.isLoadingResults = true;
      let subscription: Subscription;
      const errorHandler = commonHttpStreamErrorHandler(error => {
        if (!isNil(subscription)) {
          subscription.unsubscribe();
        }
        this.isLoadingResults = false;
        this.cdr.markForCheck();
        if (error instanceof FieldHttpError) {
          console.error(error.error.errors);
          const errorKey = Object.keys(error.error.errors)[0];
          this.remoteControlsErrors = { [errorKey]: { error: error.error.errors[errorKey][0], lastValue: saveModel[errorKey] } };

          // Kell a renderer :(
          this.ngZone.onStable
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => cmps.find(cmp => cmp.config.name === errorKey).control.updateValueAndValidity());
        } else {
          this.updateClickRowEdit.emit(false);
        }
      });

      this.remoteControlsErrors = undefined;
      if (isNew) {
        // new
        this.database.create(saveModel).subscribe(() => {
          this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.NEW_SUCCESS'));
          subscription = this.afterLoaded.pipe(take(1)).subscribe(() => finishCb());
          this.createClickRowEdit.emit(true);
          this.refresh();
        }, errorHandler);
      } else {
        // modify
        if (this.editColumnUpdateActionAutoAddId) {
          saveModel = { ...saveModel, id: editModelId };
        }
        this.database.update(editModelId, saveModel).subscribe(() => {
          this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_SUCCESS'));
          subscription = this.afterLoaded.pipe(take(1)).subscribe(() => finishCb());
          this.updateClickRowEdit.emit(true);
          this.refresh();
        }, errorHandler);
      }
    } else {
      cmps.forEach(cmp => cmp.markAsTouched());
      this.createClickRowEdit.emit(false);
    }
  }

  onInitEditControl(name: string, control: FormControl, model: unknown) {
    if (isNil(this.editFormGroup)) {
      this.editFormGroup = new FormGroup({});
    }
    this.editFormGroup.addControl(name, control);
    this.initEditControl.emit({ name, control, model });
  }

  onMultiLineEditControls(rowIndex: number, name: string, control: FormControl) {
    if (this.hasMultiLineEdit === true) {
      if (isNil(this.multiLineEditFormArray)) {
        this.multiLineEditFormArray = new FormArray([new FormGroup({})]);
      }
      const oneRowControls = this.multiLineEditFormArray.at(rowIndex) as FormGroup;

      if (oneRowControls) {
        oneRowControls.addControl(name, control);
      } else {
        this.multiLineEditFormArray.push(
          new FormGroup({
            name: control,
          })
        );
      }
    }
  }
  private filterStateSelector = states => (states.grids as GridsStateContainer).container[this.gridId].filter;

  private arrayFilterIsNotEditableAndNotDeleteable() {
    return data => isNotEditable(data) && isNotDeletable(data);
  }

  private notDeletableSnackbar(translateKey = 'GRID.DELETE.SELECTED_ROW_NOT_DELETABLE') {
    this.matSnackBar.open(this.translocoService.translate(translateKey));
  }

  private initStateActions(): void {
    this.actions$
      .pipe(
        ofActionDispatched(GridOpenBottomSheetAction),
        untilDestroyed(this),
        filter(action => action.id === this.gridId)
      )
      .subscribe(action => this.openBottomSheet(action.config));

    this.actions$
      .pipe(
        ofActionDispatched(GridCloseBottomSheetAction),
        untilDestroyed(this),
        filter(action => action.id === this.gridId)
      )
      .subscribe(() => this.matBottomSheet.dismiss());
  }

  /**
   * ha be van kapcsolva hasRadioButton true akkor megkeressuk a column definition-ben az osszes
   * radio tipusu gombot
   */
  private checkHasRadioButton(): void {
    if (this.hasRadioButton) {
      this.radioColumns = this.columnDefinitions.filter(colDef => colDef.radioColumn === true);
      this.radioColumnsState = [];
      this.radioColumns.forEach(
        radioColumnConfig =>
          (this.radioColumnsState[radioColumnConfig.radioValue] = {
            intermidate: undefined,
            all: false,
            hasDisabledColumn: false,
          })
      );
      this.columnDefinitions = this.columnDefinitions.filter(colDef => colDef.radioColumn !== true);
    }
  }

  /**
   * grid allapotat initializaljuk ha van
   */
  private initState(): void {
    const state = this.store.selectSnapshot<GridStateModel>(GridState.container(this.gridId));
    if (state !== undefined) {
      if (state.sort !== undefined) {
        /**
         * ha van sort allapot, akkor a belso valtozokat toltjuk fel az allapotbol
         */
        const sortActive = state.sort.active;
        /**
         * megnezzuk hogy letezik-e az oszlop a configban amit a state-bol kaptunk
         * ha nem akkor default sort marad
         */
        if (this.columnDefinitions.find(colDef => colDef.name === sortActive) !== undefined) {
          this.defaultSort = sortActive;
        }
        this.defaultSortDirection = state.sort.direction;
      }
      if (state.page !== undefined) {
        /**
         * ha van lapozas allapot, akkor a belso valtozokat toltjuk fel az allapotbol
         */
        this.pageSize = state.page.pageSize;
        // this.pageIndex = state.page.pageIndex;
      }
      if (state.filter !== undefined && !isEmpty(state.filter)) {
        /**
         * ha van szures allapot akkor betoltjuk a fieldbe
         */
        this.filterFormControl.patchValue(state.filter, { emitEvent: false });
      }
      /**
       * selection kezelese
       */
      this.multiSelection = state.multiselect;
    }
    this.dispatchGridInitAction();
  }

  private editData(selected): void {
    if (this.autoOpenEditRoute === true) {
      const commands = [];
      const queryParams: Params = {};
      const id = this.database.getModelIdValue(selected);
      if (isFunction(this.editDataRouteCb)) {
        commands.push(this.editDataRouteCb(id));
      } else {
        commands.push(id);
      }
      if (isFunction(this.editDataRouteQueryParamCb)) {
        this.editDataRouteQueryParamCb(id, queryParams);
      }
      this.router.navigate(commands, {
        relativeTo: this.route,
        queryParams /*, replaceUrl: true */,
      });
    }
  }

  /**
   * megjeleno oszlop definiciok init-je
   */
  private initDisplayColumns(): void {
    this.displayColumns = this.radioColumns.map(column => column.name).concat(this.columnDefinitions.map(column => column.name));
  }

  /**
   * icon column init
   */
  private initHasIconColumnInput(): void {
    if (this.hasIconColumn === true) {
      // ha van icon column akkor a masodik oszlopottol szamolunk
      let start = 1;
      if (this.hasCheckboxColumn === false) {
        // ha nincs checkbox column akkor az elso oszloptol szamolunk
        start = 0;
      }
      if (this.hasRadioButton) {
        // ha van radio button akkor a radio button utan kell rakni az elso oszlopot
        start = this.radioColumns.length;
      }
      this.displayColumns.splice(start, 0, this.iconDisplayColumnName);
    }
  }

  /**
   * szabad szavas szuro form control init
   */
  private initFilterControl(): void {
    if (this._hasFilter === true) {
      this.filterFormControl = new FormControl('', Validators.minLength(this.filterValidatorMinLength));
    }
  }

  /**
   * itt kezeljuk le a stream-ket es osszefuzve ha barmelyik valtozik akkor tudjuk hogy
   * frissitenunk kell
   */
  private initDatabaseAndSubscribeTableStreams(): void {
    /**
     * sort allapotot olvassuk ki es allitjuk be
     * utana pedig feliratkozunk a componentben levo sort -ra hogy tudjuk a state-t ertesiteni
     */
    this.sort.active = this.defaultSort;
    this.sort.direction = this.defaultSortDirection;
    this.sort.sortChange.subscribe(sort => this.store.dispatch(new GridSortChangeAction(this.gridId, sort)));
    /**
     *
     */
    // this.paginator.pageSize = this.pageSize;
    // this.paginator.pageIndex = this.pageIndex;
    // this.paginator.page.subscribe(page => this.store.dispatch(new GridPageChangeAction(this.gridId, page)));
    /**
     * stream-eket taroljuk benne hogy konyebb legyen osszefuzni majd
     * sort stream-et bele is rakjuk rogton es el is inditjuk egy alap ertekkel
     * es a paginator lapozo stream-et is init-vel bele rakjuk
     */
    const streams: Observable<any>[] = [
      this.store
        .select<Sort>(states => (states.grids as GridsStateContainer).container[this.gridId].sort)
        .pipe(distinctUntilChanged((_old, _new) => deepEqual(_old, _new))),
      this.store
        .select<GridPagingModel>(states => (states.grids as GridsStateContainer).container[this.gridId].page)
        .pipe(distinctUntilChanged((_old, _new) => deepEqual(_old, _new))),
    ];
    if (this._hasFilter === true) {
      this.filterFormControl.valueChanges
        .pipe(
          debounceTime(this.filterDelay),
          filter(value => /*value === undefined || */ value.length >= this.filterValidatorMinLength || value.length === 0),
          distinctUntilChanged(
            (oldValue, newValue) =>
              // (newValue === undefined && oldValue !== undefined) ||
              !(
                (newValue !== undefined && newValue.length === 0 && oldValue !== undefined && oldValue.length > 0) ||
                newValue !== undefined
              )
          )
        )
        .subscribe(newValue => this.store.dispatch(new GridChangeFilterValueAction(this.gridId, newValue)));
      /**
       * ha van szuro akkor kell a szuro control valtozasat is figyelni
       */
      streams.push(this.store.select<string>(this.filterStateSelector));
    }
    /**
     * ujratoltes kerest is figyelni kell
     */
    streams.push(this._refresh$.pipe(startWith(undefined)));

    /**
     * seged: utoljara kijelolt egyedeket rakjuk el hogy ujratoltes utan ki tudjuk jelolni ujra
     */
    let lastSelectedElements: any[];
    /**
     * combinaljuk a streameket
     */
    this.dataStreamSubscription = combineLatest(streams)
      .pipe(
        debounceTime(300),
        startWith(null),
        pairwise(),
        /**
         * eloszor elinditjuk az adatbazis lekerdezest
         */
        switchMap(([oldStreamsValues, streamsValues]: [any[], any[]]) => {
          if (this.runGridStateRevert) {
            this.runGridStateRevert = false;
            return of('HTTP_CANCEL' as any);
          }
          if (!this.firstAutoLoad) {
            this.firstAutoLoad = true;
            return of('HTTP_CANCEL' as any);
          }
          this.isLoadingResults = true;
          this.cdr.detectChanges();

          /**
           * PAGE-nel hozza kell adni mindent
           * SORT, FILTER es REFRESH-nel cserelni kell mindent
           */
          let trigger: 'PAGE' | 'SORT' | 'FILTER' | 'REFRESH' = 'PAGE';
          if (Array.isArray(oldStreamsValues)) {
            if (!deepEqual(oldStreamsValues[0], streamsValues[0])) {
              trigger = 'SORT';
            } else if (oldStreamsValues[2] !== streamsValues[2]) {
              trigger = 'FILTER';
            } else if (deepEqual(oldStreamsValues, streamsValues)) {
              trigger = 'REFRESH';
            }
          }

          if (['SORT', 'REFRESH'].includes(trigger)) {
            if (this.enablePagingStore) {
              streamsValues[1] = {
                pageSize: streamsValues[1].pageSize * (streamsValues[1].pageIndex + 1),
                pageIndex: 0,
              };
            } else {
              streamsValues[1] = { pageSize: this.pageSize, pageIndex: 0 };
            }
          }

          let returnObserver: Observable<HttpListResponseModel<any>> | undefined;

          if (
            this.checkModified &&
            ((this.hasRadioButton && this._changedDataSourceData) ||
              (this.disableSelection === false && this.selection.selected.length > 0))
          ) {
            returnObserver = new Observable<HttpListResponseModel<any>>(observer => {
              checkModifiedFormPopup(result => {
                if (result.result === true) {
                  // mehet tovabb
                  this.runHttpCallback(streamsValues).subscribe(v => {
                    observer.next(v);
                    observer.complete();
                  });
                } else {
                  // revert
                  this.revertGridState();
                  observer.next('HTTP_CANCEL' as any);
                  return observer.complete();
                }
              });
            });
          }
          // if (this.firstResponseFlag && streamsValues[1].pageIndex > 0) {
          //   if (this.enablePagingStore) {
          //     // ebben az esetben grid helyreallitas van, ezert le kell kerni az eddigi osszes adatot, viszont ezt a state-be nem rakjuk mar vissza!
          //     streamsValues[1] = { pageSize: streamsValues[1].pageSize * (streamsValues[1].pageIndex + 1), pageIndex: 0 };
          //   } else {
          //     streamsValues[1] = { pageSize: this.pageSize, pageIndex: 0 };
          //   }
          // }
          console.log(streamsValues);
          if (returnObserver === undefined) {
            returnObserver = this.runHttpCallback(streamsValues);
          }

          return returnObserver.pipe(map(response => ({ response, trigger })));
        }),
        /**
         * adatbazis lekerdezes feldolgozasa
         */
        switchMap(result => {
          const { response, trigger } = result;
          if (isString(result) && result === 'HTTP_CANCEL') {
            return of('CANCEL' as any);
          }
          if (isNil(response.results)) {
            // FIX
            console.warn(logScopeName, 'NOT FOUND GRID RESPONSE RESULTS');
            response.results = [];
            response.count = 0;
          }
          this.resultsLength = response.count;
          this.enabledSortColumns = response.ordering_fields;
          this.iconColumnDatas =
            trigger === 'PAGE'
              ? [...(this.iconColumnDatas ?? []), ...(this.handleIconColumn(response) ?? [])]
              : this.handleIconColumn(response);

          if (!this.disableSelection) {
            /**
             * eltarljuk az utoljara kijelolt egyedeket
             */
            lastSelectedElements = this.store
              .selectSnapshot<GridStateModel>(GridState.container(this.gridId))
              .selected.map(selected => response.results.find(responseResult => this.database.getModelIdValue(responseResult) === selected))
              .filter(x => x !== undefined); //this.getLastSelectedFromCurrentSelection(lastSelectedElements, response);
            /**
             * eltavolitjuk a kijelolteket
             */
            this.disableNextSelectionClear = lastSelectedElements.length > 0;
            if (this.selection.selected.length > 0) {
              this.selection.clear();
            }
          }

          /**
           * jelezzuk kifele az uj adatokat
           */
          this.remoteRefresh.emit(result);

          return of(result);
        }),
        catchError(error => {
          console.error(error);
          this.isLoadingResults = false;
          // TEMP megoldas
          if (error.message.indexOf('ViewDestroyedError') === -1 && error.status === 500) {
            MaybeHandleHttpError.maybeHandleHttpError(error);
          }
          return of({ response: { results: [] }, trigger: '' });
        })
      )
      .subscribe(
        result => {
          const { response, trigger } = result as { response: HttpListResponseModel<any>; trigger: string };
          const after = () => {
            this.isLoadingResults = false;
            this.runRefresh = false;
            this.cdr.detectChanges();
          };
          if (isString(result) && result === 'CANCEL') {
            return after();
          }
          this.originalHttpResponse = tsDeepcopy(response);
          this.originalHttpResponseIconColumnDatas = tsDeepcopy(this.iconColumnDatas);
          if (this.remoteResponseResultsMapper !== undefined) {
            response.results = this.remoteResponseResultsMapper(response.results);
          }
          const { results } = response;
          let pageSize = this.store.selectSnapshot(states => (states.grids as GridsStateContainer).container[this.gridId].page.pageSize);
          let pageIndex = this.store.selectSnapshot(states => (states.grids as GridsStateContainer).container[this.gridId].page.pageIndex);
          if (['SORT', 'REFRESH'].includes(trigger)) {
            if (this.enablePagingStore) {
              pageSize = pageSize * (pageIndex + 1);
              pageIndex = 0;
            } else {
              pageIndex = 0;
            }
          }
          // this.hasNexPage = this.firstResponseFlag ? pageSize * (pageIndex + 1) <= results.length : pageSize <= results.length;
          this.hasNexPage = pageSize <= results.length;
          this.dataSource.data = trigger === 'PAGE' ? [...this.dataSource.data, ...results] : results;
          this.originalDataSoureData = tsDeepcopy(this.dataSource.data);
          this._changedDataSourceData = false;
          this.resetChangedDetect();
          if (
            this.checkShowFilter === true &&
            this.hasFilter === true &&
            this.showFilter === true &&
            this.store.selectSnapshot(this.filterStateSelector).length === 0
          ) {
            // https://roadrecord.myjetbrains.com/youtrack/issue/RROHU-2585
            this.checkShowFilter = false;
            if (this.dataSource.data.length === 0) {
              this.showFilter = false;
            }
          }
          // if (this.firstResponseFlag) {
          //   this.restoreScroll();
          // }

          if (this.hasRadioButton) {
            this.recalculateRadioButtonsState();
          }

          if (
            !this.disableSelection &&
            !isNil(lastSelectedElements) &&
            Array.isArray(lastSelectedElements) &&
            /* sort es page eseten */ ['SORT', 'PAGE'].indexOf(
              this.store.selectSnapshot<GridStateModel>(GridState.container(this.gridId)).lastAction
            ) === -1
          ) {
            const filteredLastSelectedElements = lastSelectedElements
              .map(lastSelectedElement =>
                response.results.find(
                  responseResult => this.database.getModelIdValue(responseResult) === this.database.getModelIdValue(lastSelectedElement)
                )
              )
              .filter(lastSelectedElement => lastSelectedElement !== undefined);
            if (this.firstResponseFlag === false) {
              this.disableNextSelectionClear = filteredLastSelectedElements.length > 0;
            }
            if (filteredLastSelectedElements.length > 0) {
              this.selection.select(...filteredLastSelectedElements);
            }
          }

          this.checkAndSetFirstResponseFlag(response);

          this.afterLoaded.emit(response);

          after();
        },
        error =>
          MaybeHandleHttpError.maybeHandleHttpError(error, () => {
            this.isLoadingResults = false;
            this.runRefresh = false;
            this.cdr.detectChanges();
          })
      );
  }

  // private restoreScroll(): void {
  //   const scrollTop: number = this.store.selectSnapshot<GridStateModel>(GridState.container(this.gridId)).scrollTop;
  //   if (!isNil(scrollTop) && isNumeric(scrollTop)) {
  //     if (this.tableWrapperElement.first.nativeElement.scrollTop !== scrollTop) {
  //       this.disableSaveScrollState = true;
  //       scrollTo(this.tableWrapperElement.first.nativeElement, scrollTop, 250, this.ngZone, () =>
  //         timer(1000)
  //           .pipe(untilDestroyed(this))
  //           .subscribe(() => (this.disableSaveScrollState = false))
  //       );
  //     }
  //   }
  // }

  private checkAndSetFirstResponseFlag(data): void {
    if (this.firstResponseFlag === true) {
      /**
       * firstResponse kezelese
       */
      this.firstResponse.emit(data);
      this.firstResponseFlag = false;
    }
  }

  /**
   * icon column kezelesert felelos request eseten
   */
  private handleIconColumn(data): any[] {
    if (this.hasIconColumn === true) {
      return data.results.map(model => {
        const cellTemplateModel: string | IconColumnDataModel = this.setIconFn(model);

        if (!isNil(cellTemplateModel)) {
          if (isString(cellTemplateModel)) {
            return {
              value: cellTemplateModel,
            };
          } else {
            return cellTemplateModel;
          }
        }
        return null;
      });
    }
    return null;
  }

  /**
   * selection input kezelese
   */
  private initSelectionInput(): void {
    if (this.selection === undefined) {
      if (this.multiSelection === true) {
        this.selection = new SelectionModel<any>(this.multiSelection, []);
      } else {
        this.selection = new SelectionModel<any>(this.multiSelection);
      }
    }

    const state = this.store.selectSnapshot<GridStateModel>(GridState.container(this.gridId));
    let stateChangeLength = -1;
    if (state !== undefined && state.selected !== undefined) {
      if (Array.isArray(state.selected)) {
        if (this.selection.isMultipleSelection()) {
          stateChangeLength = state.selected.length;
          if (stateChangeLength > 0) {
            this.selection.select(...state.selected);
          }
        } else {
          if (state.selected.length > 0) {
            this.selection.select(state.selected[0]);
          }
        }
      } else {
        // TODO ennek nem minus 1nek kene lennie?
        this.selection.select(state.selected);
      }
    }

    let snackbarRef: MatSnackBarRef<SimpleSnackBar>;
    this.selection.changed.subscribe(selectionChange => {
      if (this.disableNextSelectionClear === true) {
        this.disableNextSelectionClear = false;
        return;
      }
      this.maybeCloseRightClickContextMenu();

      if (!isNil(snackbarRef)) {
        snackbarRef.dismiss();
      }
      this.selectionChange.emit(this.selection.selected);
      this.selectionChanges.emit(selectionChange);

      if (this.runRefresh === false) {
        if (selectionChange.removed.length === 0) {
          this.store.dispatch(
            new GridAddSelectionAction(
              this.gridId,
              selectionChange.added.map(added => this.database.getModelIdValue(added))
            )
          );
          if (this.selection.selected.length === 1) {
            const selected = this.selection.selected[0];

            snackbarRef = this.snackbarMessageEditableOrDeletableOrTogether(selected, snackbarRef);
          } else if (this.selection.selected.length > 0) {
            if (selectionChange.added.length === 1) {
              if (isNotDeletable(selectionChange.added[0])) {
                if (!isNotEditable(selectionChange.added[0])) {
                  // this.notDeletableSnackbar('GRID.DELETE.SELECTED_ROW_NOT_DELETABLE_AND_NOT_EDITABLE');
                  // } else {
                  this.notDeletableSnackbar();
                }
              }
            } /*else {
              const added =
                this.selection.selected.length===this.dataSource.data.length ? this.selection.selected:selectionChange.added;
              // kijelolt elemekben van nem torolheto elem is: ...
              const notDeleteableSelectedElements = added
                .filter(add => hasDeletable(add) && isNotDeletable(add))
                .map(selected => this.database.getToString(selected));
              if (notDeleteableSelectedElements.length > 0) {
                this.translocoService
                  .selectTranslate('GRID.DELETE.SELECTED_ROWS_HAS_NOT_DELETABLE_ITEMS', {
                    text: notDeleteableSelectedElements.join(', ')
                  })
                  .pipe(untilDestroyed(this))
                  .subscribe((translatedText: string) => {
                    snackbarRef = this.matSnackBar.open(translatedText);
                  });
              }
            }*/
          }
        } else {
          this.store.dispatch(
            new GridRemoveSelectionAction(
              this.gridId,
              selectionChange.removed.map(removed => this.database.getModelIdValue(removed))
            )
          );
        }
      }
      this.cdr.markForCheck();
    });
  }

  private snackbarMessageEditableOrDeletableOrTogether(selected, snackbarRef: MatSnackBarRef<SimpleSnackBar>) {
    const _hasEditable = hasEditable(selected);
    const _hasDeletable = hasDeletable(selected);
    const _isNotEditable = isNotEditable(selected);
    const _isNotDeletable = isNotDeletable(selected);

    /*if (_hasEditable && _hasDeletable && _isNotEditable && _isNotDeletable) {
      snackbarRef = this.matSnackBar.open(this.translocoService.translate('GRID.DELETE.SELECTED_ROW_NOT_DELETABLE_AND_NOT_EDITABLE'));
    } else*/
    if (_hasEditable && _isNotEditable) {
      if (this.hasDetailsView) {
        snackbarRef = this.matSnackBar.open(this.translocoService.translate('GRID.DELETE.SELECTED_ROW_NOT_EDITABLE'));
      }
    } else if (_hasDeletable && _isNotDeletable) {
      // kijelolt elem nem torolheto
      this.notDeletableSnackbar();
    }
    return snackbarRef;
  }

  /**
   * checkbox oszlop input kezelese
   */
  private initHasCheckboxColumnInput(): void {
    if (this.hasCheckboxColumn === true) {
      this.displayColumns.unshift('select');
    }
  }

  /**
   * edit oszlop kezelese
   */
  private initHasActionsColumnInput(): void {
    if (this.hasActionsColumn === true && this.hasCommonActionsColumn === false) {
      this.displayColumns.push(this.actionsDisplayColumnName);
    }
  }

  private initHasCommonActionsColumnInput(): void {
    if (this.hasCommonActionsColumn === true && this.hasActionsColumn === false) {
      this.displayColumns.push(this.commonActionsDisplayColumnName);
    }
  }

  private initHasSummaryRow(): void {
    if (this.hasSummaryRow === true) {
      this.footerDisplayColumns = this.columnDefinitions
        .filter(dc => isFunction(dc.columnSummaryContentTemplateCallback))
        .map(item => item.name);
    }
  }

  /**
   * kotelezo input-kat ellenorizzuk
   */
  private checkRequiredInputs(): void {
    if (this.columnDefinitions === undefined) {
      throw new Error('Not found displayed columns!');
    }

    if (this.hasIconColumn === true) {
      if (this.iconDisplayColumnName === undefined) {
        throw new Error('Not found icon name!');
      }
      if (this.setIconFn === undefined) {
        this.setIconFn = (model: any) => {
          if (this.setIconPropertyName === undefined) {
            throw new Error('Not found icon property column name or function');
          } else {
            const icon: string = model[this.setIconPropertyName];
            if (icon.indexOf('.') > -1) {
              return icon.split('.')[0];
            } else {
              return icon;
            }
          }
        };
      }
    }
  }

  private checkRadioIsDisabled(column: GridColumnModel<any>, element: any): boolean {
    return (
      column.disableRadio !== undefined &&
      ((typeof column.disableRadio === 'boolean' && column.disableRadio) || column.disableRadio(element))
    );
  }

  private recalculateRadioButtonsState(): void {
    const newRadioColumnsState: { all: boolean; intermidate: boolean; hasDisabledColumn: boolean }[] = [];

    this.radioColumns.forEach(radioColumnConfig => {
      if (this.dataSource.data === undefined || this.dataSource.data.length === 0) {
        // ha nincs datasource akkor akkor semmit sem allitunk be
        return (this.radioColumnsState[radioColumnConfig.radioValue] = {
          all: false,
          intermidate: false,
          hasDisabledColumn: false,
        });
      }
      const all = this.dataSource.data.find(data => data[radioColumnConfig.radioName] !== radioColumnConfig.radioValue) === undefined;
      const intermidate =
        !all && this.dataSource.data.find(data => data[radioColumnConfig.radioName] === radioColumnConfig.radioValue) !== undefined;

      newRadioColumnsState[radioColumnConfig.radioValue] = {
        all,
        intermidate,
        hasDisabledColumn:
          radioColumnConfig.disableRadio !== undefined &&
          this.dataSource.data.find(data => this.checkRadioIsDisabled(radioColumnConfig, data)) !== undefined,
      };
    });
    if (newRadioColumnsState.length > 0) {
      this.radioColumnsState = newRadioColumnsState;
    }
  }

  private checkChangedDataSourceData(changedRows: any[]): boolean {
    const check = deepEqual(this.originalDataSoureData, this.dataSource.data);
    if (check === this._changedDataSourceData) {
      this._changedDataSourceData = !check;
      this.changedDataSourceData.emit(this._changedDataSourceData);
    }
    // emit changed datas
    const changedDatas = { add: [], revert: [] };
    changedRows.forEach(model => {
      if (
        !deepEqual(
          model,
          this.originalDataSoureData.find(_model => _model.id === model.id)
        )
      ) {
        changedDatas.add.push(model);
      } else {
        changedDatas.revert.push(model);
      }
    });
    this.changeDataSourceData.emit(changedDatas);
    return this._changedDataSourceData;
  }

  private runHttpCallback(streamsValues: any[]): Observable<HttpListResponseModel<any>> {
    if (this.databaseCallback !== undefined) {
      // TODO refactor by state
      return this.databaseCallback(this.database, streamsValues[0], streamsValues[1], streamsValues[2]);
    }
    // TODO: ts hiba miatt nem megy return this.database.getAll(...streamsValues);
    return this.database.getAll(streamsValues[0], streamsValues[1], streamsValues[2]);
  }

  private maybeCloseRightClickContextMenu(): void {
    const rightClickContextMenuOverlayRef = this.contextMenuService.getLastAttachedOverlay();
    if (
      rightClickContextMenuOverlayRef !== undefined &&
      rightClickContextMenuOverlayRef.contextMenu.menuClass === this.rightClickContextMenuClass
    ) {
      rightClickContextMenuOverlayRef.detach();
      rightClickContextMenuOverlayRef.dispose();
    }
  }

  private openBottomSheet(config: GridBottomSheetConfig<any>): MatBottomSheetRef {
    return this.matBottomSheet.open(GridBottomSheetComponent as any, {
      data: config,
      disableClose: true,
      closeOnNavigation: false,
      restoreFocus: true,
      panelClass: 'grid-bottom-sheet',
    });
  }

  private handleRowEditFinish() {
    this.finishRowEdit.pipe(untilDestroyed(this)).subscribe(() => {
      delete this.editFormGroup;
      this.disableSelection = false;
      this.disableCheckboxColumn = false;
      if (!isNil(this.filterFormControl)) {
        this.filterFormControl.enable();
      }
      // this.paginator.disabled = false;
      this.disableFloatingButton$.next(false);
      this.cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes.openAllEditInput) && changes.openAllEditInput.currentValue === true) {
      if (this.hasActionsColumn) {
        throw new Error('openAllEditInput = true nem hasznalhato egyutt a hasActionsColumn = true -val');
      } else {
        this.dataSource.data.forEach((d, index) => this.onClickRowEdit(index, d));
      }
    }
  }

  translateIfNeeded(useTranslation: boolean | undefined, value: string | undefined): string {
    if (isNil(useTranslation) || useTranslation === true) {
      return this.translocoService.translate(value);
    } else {
      return value;
    }
  }

  getNextPage() {
    this.store.dispatch(new GridLoadNextPageAction(this.gridId));
  }
}
