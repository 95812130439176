import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { fadeInEnter } from '@roadrecord/animations';
import { PRICE_SITE_PATH, USER_LOGIN_PATH, USER_ROUTE_PATH, ViewportService, WINDOW } from '@roadrecord/common/common';
import { CompanyContextState, goToOrderInANewTab, SubscriptionInfoEnum } from '@roadrecord/company-context/common';
import { I18nState, SelectLanguageAction } from '@roadrecord/i18n-state';
import { isMobile, isTablet } from '@roadrecord/mobile-detect';
import { AuthState, LogoutAction, UserModel } from '@roadrecord/user/common';
import { commonHttpStreamErrorHandler, IS_WEBADMIN, openWindowOrMessageDialog } from '@roadrecord/utils';
import { Observable, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AddAppOverlayAction, RemoveAppOverlayAction } from '@roadrecord/app-layout-state';
import { StickyNotificationOption } from '@roadrecord/app-base';
import { AppTypeEnum, environment, LOCALES, LOCALESgetValues } from '@roadrecord/environment';
import { MenuLastRouteStateModel, MenuService, MenuState, MenuToggleAction } from '@roadrecord/app-layout/common';

@Component({
  selector: 'rr-nav-toolbar',
  templateUrl: './nav-toolbar.component.html',
  styleUrls: ['./nav-toolbar.component.scss'],
  animations: [fadeInEnter],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavToolbarComponent implements AfterViewInit {
  availableLanguages: string[] = LOCALESgetValues();
  @Select(AuthState.user)
  readonly user$: Observable<UserModel>;
  @Select(AuthState.isLoggedIn)
  readonly isLoggedIn$: Observable<boolean>;
  @Select(CompanyContextState.companyName)
  readonly companyName$: Observable<string>;
  @Select(CompanyContextState.subscriptionState)
  readonly expiredSubscription$: Observable<SubscriptionInfoEnum>;
  @Select(CompanyContextState.showOnSaleEndDate)
  readonly showOnnSaleEndDate$: Observable<boolean>;
  @Select(CompanyContextState.isTrialTimeExpired)
  readonly isExpiredTrialTime$: Observable<boolean>;
  SubscriptionInfoEnumValues = Object.values(SubscriptionInfoEnum);
  SubscriptionInfoEnum = SubscriptionInfoEnum;
  firstStepsMode = false;
  isLoginRoute = false;
  isRegisterRoute = false;
  showMenuButtons: boolean;
  openedMenu: boolean;
  is404ErrorPage = false;
  currentMenuState: MenuLastRouteStateModel;
  isMobileOrTablet = isTablet || isMobile;
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  @Select(I18nState.selectedLang)
  currentLangCode$: Observable<LOCALES>;

  constructor(
    readonly menuService: MenuService,
    private readonly translocoService: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
    readonly viewportService: ViewportService,
    readonly router: Router,
    @Inject(IS_WEBADMIN) readonly isWebadmin: boolean,
    @Inject(DOCUMENT) readonly document: Document,
    private readonly store: Store,
    @Inject(WINDOW) private window: Window,
    private http: HttpClient
  ) {
    // read show menu state
    this.store
      .select(MenuState.show)
      // extra szabalyok figyelese
      .pipe(
        switchMap(show =>
          this.store
            .select(AuthState.socialRegistrationSuccessfulMode)
            .pipe(map(socialRegistrationSuccessfulMode => !socialRegistrationSuccessfulMode && show))
        )
      )
      .subscribe(show => (this.showMenuButtons = show));
    // read menu open state
    this.store.select(MenuState.open).subscribe(open => {
      this.openedMenu = open;
      // azert kell itt mert amikor kis kepernyon vagyunk akkor a sidenav closedStart esemeny utan nem jo a cdr
      this.cdr.markForCheck();
    });
    this.store
      .select(states => /* circular dep ha state ref-vel probaljuk */ states.firstSteps.finished || this.isWebadmin)
      .subscribe(finished => (this.firstStepsMode = !finished));

    // CDR miatt
    menuService.currentStateChange$.subscribe(currentStateChange => {
      this.currentMenuState = currentStateChange;
      this.cdr.markForCheck();
    });

    // this.handleRouteChange(this.router.url);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe(url => this.handleRouteChange(url));
  }

  private _stickyNotificationOptions: StickyNotificationOption;

  get stickyNotificationOptions(): StickyNotificationOption {
    return this._stickyNotificationOptions;
  }

  @Input()
  set stickyNotificationOptions(value: StickyNotificationOption) {
    if (value !== undefined) {
      this.document.body.classList.add('has-sticky-notification');
    } else {
      this.document.body.classList.remove('has-sticky-notification');
    }
    timer(0).subscribe(() => {
      this._stickyNotificationOptions = value;
      this.cdr.markForCheck();
    });
  }

  logout($event: Event): boolean {
    $event.stopPropagation();
    $event.preventDefault();

    this.store.dispatch(new LogoutAction());

    return false;
  }

  /**
   * jelenlegi nyelvet allitjuk be
   */
  setLang(key: LOCALES): void {
    this.store.dispatch(new SelectLanguageAction(key));
  }

  sideToggle(): void {
    this.store.dispatch(new MenuToggleAction());
  }

  openMyProfileMenu(): void {
    if (this.store.selectSnapshot<boolean>(MenuState.show)) {
      this.menuService.visibleOneMenuPage('USER_PROFILE_MENU');
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  onDismissStickyNotification(cancel: boolean): void {
    if (this.stickyNotificationOptions.dismissCallback !== undefined) {
      this.stickyNotificationOptions.dismissCallback();
    }
    this.stickyNotificationOptions = undefined;
  }

  /**
   * TODO csak nem belepett user eseten!
   */
  private handleRouteChange(url: string): void {
    this.is404ErrorPage = url === '/404';
    timer(0).subscribe(() => {
      const _url = this.router.url;
      this.is404ErrorPage = _url === '/404';
      this.cdr.markForCheck();
    });
    if (url.indexOf(USER_LOGIN_PATH) > -1) {
      this.isLoginRoute = true;
      this.isRegisterRoute = false;
    } else if (url === `/register`) {
      this.isLoginRoute = false;
      this.isRegisterRoute = true;
    } else {
      this.isLoginRoute = false;
      this.isRegisterRoute = false;
    }
    this.cdr.markForCheck();
  }

  onClickExpiresSubscription(expiredSubscription: SubscriptionInfoEnum) {
    /*
    #KIADASHOZ-REGI
    goToOrderInANewTab(expiredSubscription, this.http, this.translocoService);
*/
    if (this.isUsAppType === true) {
      this.menuService.visibleOneMenuPage('USER_PROFILE_MENU');
      this.router.navigate([`/${USER_ROUTE_PATH}/${PRICE_SITE_PATH}`]);
    } else {
      goToOrderInANewTab(expiredSubscription, this.http, this.translocoService);
    }
  }

  onClickSwitchUser() {
    this.store.dispatch(new AddAppOverlayAction(this.translocoService.translate('APP_LAYOUT.NAV_TOOLBAR.SWITCH_USER.OVERLAY')));
    this.http.get<{ token: string }>(`${environment.apiUrl}auth/staff/logged-back/`).subscribe(
      response => {
        this.window.localStorage.clear();
        this.window.location.replace(
          `${environment.switchToUserUrl}/?id=${this.store.selectSnapshot(CompanyContextState.id)}&tgkjhdiu4thresafdsdf=${response.token}`
        );
      },
      commonHttpStreamErrorHandler(() => this.store.dispatch(new RemoveAppOverlayAction()))
    );
  }

  onClickOnSaleEndDate() {
    /*
    #KIADASHOZ-REGI
    this.http.get<{ link: string }>(`${environment.apiUrl}company/redirect/go-to-on-sale/`).subscribe(
      result =>
        openWindowOrMessageDialog({
          linkName: this.translocoService.translate('APP_LAYOUT.NAV_TOOLBAR.ITEM.ON_SALE_END_DATE'),
          linkUrl: result.link,
          windowTarget: '_blank',
        }),
      commonHttpStreamErrorHandler()
    );
*/
    if (this.isUsAppType === true) {
      this.menuService.visibleOneMenuPage('USER_PROFILE_MENU');
      this.router.navigate([`${USER_ROUTE_PATH}/price`], { queryParams: { category_type: 'on-sale' } });
    } else {
      this.http.get<{ link: string }>(`${environment.apiUrl}company/redirect/go-to-on-sale/`).subscribe(
        result =>
          openWindowOrMessageDialog({
            linkName: this.translocoService.translate('APP_LAYOUT.NAV_TOOLBAR.ITEM.ON_SALE_END_DATE'),
            linkUrl: result.link,
            windowTarget: '_blank',
          }),
        commonHttpStreamErrorHandler()
      );
    }
  }
}
