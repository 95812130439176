<button
  [id]="gaPrefix + '-submit'"
  type="button"
  [disabled]="disabled"
  [ngClass]="{ 'invalid-form': formSubmitted && formInvalid }"
  mat-raised-button
  (click)="onClickButton()"
  color="primary"
  class="submit-button"
>
  <div
    class="invalid-form-button-overlay"
    *ngIf="formSubmitted && formInvalid"
    matTooltipPosition="left"
    [matTooltip]="formSubmitted === true && formInvalid ? (invalidTooltip | transloco) : undefined"
  >
    <mat-icon svgIcon="alert"></mat-icon>
  </div>
  <span>{{ buttonLabel | transloco }}</span>
  <mat-icon *ngIf="buttonIcon" class="buttonIcon">
    {{ buttonIcon }}
  </mat-icon>
</button>
