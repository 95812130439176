import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { checkPropertyChange, deepEqual, simpleFadeInAnimation, slideTopOutAnimation } from '@roadrecord/common/common';
import { RightClickMenuItemModel } from '@roadrecord/right-click-menu';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { CalendarMonthViewBeforeRenderEvent, CalendarUtils, DAYS_OF_WEEK } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skip, startWith, switchMap, tap } from 'rxjs/operators';
import { RRCalendarEvent } from '../model/rr-calendar-event';
import { RRCalendarMonthViewDay } from '../model/rr-calendar-month-view-day';
import { RRSpecialDayEventModel } from '../model/rr-special-day-event.model';
import { setHeaderDayNumberCssClass } from '../utils';
import { RRCalendarUtils } from './rr-calendar-utils';
import { RRCalendarViewType } from './rr-calendar-view-type';
import { gridDatabaseCallback } from '@roadrecord/grid';
import { AbstractEntityService, ENTITY_SERVICE_TOKEN, HttpListResponseModel, MaybeHandleHttpError } from '@roadrecord/utils';
import { FillCalendarCb } from '../model/fill-calendar-cb.type';
import { MenuService, notAccessRedirect } from '@roadrecord/app-layout/common';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { CalendarChangeViewTypeAction } from '../state/action/calendar-change-view-type.action';
import { SplitButtonAction } from '@roadrecord/split-button';
import { RRCalendarHeaderButton } from '../model/rr-calendar-header-button';
import { CalendarHeaderOtherAction } from '../types/calendar-header-other.action';
import { FormArray, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@UntilDestroy()
@Component({
  selector: 'rr-calendar-layout',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideTopOutAnimation, simpleFadeInAnimation],
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  showViewSelector = false;
  @Input()
  databaseCallback: gridDatabaseCallback;
  @HostBinding('class.rr-calendar-not-ready')
  isLoading = true;
  @Input()
  isLoadingWithoutMessages = false;
  @Input()
  viewDate: Date;
  @Input()
  view = 'month';
  @Input()
  weekStartsOn: DAYS_OF_WEEK = DAYS_OF_WEEK.MONDAY;
  @Input()
  weekendDays: DAYS_OF_WEEK[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  @Input()
  cellHeaderTemplate: TemplateRef<any>;
  @Input()
  cellContainerTemplate: TemplateRef<any>;
  @Input()
  cellColors: { [name: string]: EventColor } = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
  };
  @Input()
  cellColor = 'red';
  @Input()
  setHeaderDayNumberCssClassFn = setHeaderDayNumberCssClass;
  @Input()
  headerText: string;
  /**
   * ha ez a valtozo true akkor nem vesszuk figyelembe a hasListViewButton input erteket!
   */
  @Input()
  replaceHeaderTextTemplateToFullCustomReference = false;
  /**
   * ha replaceHeaderTextTemplateToFullCustomReference false csak akkor vesszuk figyelembe az input erteket
   */
  @Input()
  @HostBinding('class.has-list-view-button')
  hasListViewButton = true;
  @Input()
  listItemCellTemplateRef: TemplateRef<any>;
  @Input() listItemTitleAfterDateCellTemplateRef: TemplateRef<any>;
  @Input()
  listItemDescriptionCellTemplateRef: TemplateRef<any>;
  @Input()
  newButtonTooltip: string;
  @Input()
  modifyButtonTooltip: string;
  @Input()
  titleIcon: string;
  @Input()
  readonly hasSpecialDaysLayer = true;
  @Input()
  beforeTopRightButtonsTemplateRef: TemplateRef<any>;
  @Input()
  headerTextTemplate: TemplateRef<any>;
  @Input()
  loadedMessage: string;
  @Output()
  clickCell = new EventEmitter<RRCalendarMonthViewDay>();
  @Output()
  beforeViewRender = new EventEmitter<CalendarMonthViewBeforeRenderEvent>();
  @Input() readonly viewType: RRCalendarViewType = RRCalendarViewType.CALENDAR;
  @Input() readonly fillCalendarCb: FillCalendarCb;
  RRCalendarViewType = RRCalendarViewType;

  private firstLoaded = true;
  events$: Observable<{ events: RRCalendarEvent[]; calendarRowCount: number }>;
  listEvents$: Observable<{ events: RRCalendarEvent[] }>;
  private calendarUtils: RRCalendarUtils;
  calendarId: string;
  @Input()
  rightClickContextMenuOption: RightClickMenuItemModel<any>[];

  @Input() enabledRightClickContextMenu = false;
  @Output() contextMenuOnDayCell = new EventEmitter<any>();
  // @Input() topRightMenuBeforeButtons: (RRCalendarTopRightMenuButtonOption | RRCalendarTopRightMenuDividerOption)[];
  // @Input() topRightMenuAfterButtons: (RRCalendarTopRightMenuButtonOption | RRCalendarTopRightMenuDividerOption)[];
  // @Input() topRightMenuExtraButton: RRCalendarTopRightMenuButtonOption;
  @Input() topRightMenuAfterButtons: SplitButtonAction[];
  @Input() topRightMenuAfterButtonColor: ThemePalette | undefined = 'accent';

  // Extra gombo
  @Input() topRightMenuExtraButton: RRCalendarHeaderButton;
  @Input() calendarTopRightMenuClass: string;
  @Output() periodContextChange = new EventEmitter<boolean>();
  @Input() enableNotAccessRedirect = true;
  @ViewChildren('topRightMenuTrigger') private topRightMenuTrigger: QueryList<MatMenuTrigger>;

  @Input() headerOtherActions: CalendarHeaderOtherAction<any>[];
  @Input() headerOtherActionTooltip: string;
  @Input() headerOtherActionButtonColor: ThemePalette | undefined = 'primary';
  @Input() headerOtherActionButtonType = 'mat-raised-button';

  @Input() headerFixTripImportActions: CalendarHeaderOtherAction<any>[];
  @Input() headerFixTripImportActionsTooltip: string;
  @Input() headerFixTripImportActionButtonColor: ThemePalette | undefined = 'accent';
  @Input() headerFixTripImportActionButtonType = 'mat-raised-button';

  locale: string;
  calendarOptions: { days: RRSpecialDayEventModel[]; start_date: string; end_date: string };
  private _refresh$ = new EventEmitter<void>();
  options: any;
  private intersectionObserver: IntersectionObserver;

  selectedTripEvent: any;
  selectedTrips: FormGroup;

  selectedCalendarViewType: RRCalendarViewType = RRCalendarViewType.CALENDAR;
  tripActionHasSuccess: Date | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private actions$: Actions,
    @Inject(ENTITY_SERVICE_TOKEN) private database: AbstractEntityService<HttpListResponseModel<any>, any>,
    calendarUtils: CalendarUtils,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private menuService: MenuService,
    private injector: Injector
  ) {
    this.calendarUtils = calendarUtils as RRCalendarUtils;
    this.locale = translocoService.getActiveLang();
    translocoService.langChanges$.pipe(untilDestroyed(this), debounceTime(200), distinctUntilChanged()).subscribe(locale => {
      this.locale = locale;
      this.cdr.markForCheck();
    });
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.isLoading = true;
        this.menuService.hasMenuItemByUrl().subscribe(v => {
          this.periodContextChange.emit(v);

          if (v) {
            this.refresh();
          } else {
            if (this.enableNotAccessRedirect) {
              notAccessRedirect(this.injector);
            }
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.matSnackBar.dismiss();
    if (!isNil(this.intersectionObserver)) {
      this.intersectionObserver.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.events !== undefined &&
      (changes.events.isFirstChange() || changes.events.previousValue !== changes.events.currentValue) &&
      changes.events.currentValue !== null
    ) {
      const events: RRCalendarEvent[] = changes.events.currentValue.data;
      events.map(event => {
        if (event.color === undefined && event.color !== this.cellColors[this.cellColor]) {
          event.color = this.cellColors[this.cellColor];
        }
        return event;
      });
    }

    if (checkPropertyChange('isLoading', changes)) {
      const isLoadingWithoutMessages = checkPropertyChange('isLoadingWithoutMessages', changes)
        ? changes.isLoadingWithoutMessages.currentValue
        : this.isLoadingWithoutMessages;
      if (isLoadingWithoutMessages === false) {
        timer(0).subscribe(() => {
          // Material animation fix
          if (changes.isLoading.currentValue === true) {
            this.translocoService
              .selectTranslate(`CALENDAR.SNACKBAR.${this.firstLoaded ? 'LOADING' : 'REFRESHING'}`)
              .pipe(untilDestroyed(this))
              .subscribe(translatedText =>
                this.matSnackBar.open(translatedText, this.translocoService.translate('COMMON.ACTION.CLOSE'), {
                  duration: 1500,
                  verticalPosition: 'bottom',
                })
              );
          } else {
            const snackBarRenderer = text =>
              this.matSnackBar.open(text, this.translocoService.translate('COMMON.ACTION.CLOSE'), {
                duration: 1500,
                verticalPosition: 'bottom',
              });
            if (this.loadedMessage) {
              snackBarRenderer(this.loadedMessage);
            } else {
              this.translocoService
                .selectTranslate(`CALENDAR.SNACKBAR.${this.firstLoaded ? 'LOADED' : 'REFRESHED'}`)
                .pipe(untilDestroyed(this))
                .subscribe(translatedText => snackBarRenderer(translatedText));
            }

            this.firstLoaded = false;
          }
        });
      }
    }
  }

  ngOnInit(): void {
    // Nem ertkezik meg bootstrapban, idoben a valasz
    timer(500).subscribe(() => {
      const dayNumber = (moment.localeData() as any)._week.dow;
      this.weekStartsOn = dayNumber;
      // let lastWeekDay = dayNumber + 5;
      // if (lastWeekDay > 6) {
      //   lastWeekDay -= 6;
      // }
      // let prevLastWeekDay = lastWeekDay - 1;
      // if (prevLastWeekDay > 6) {
      //   prevLastWeekDay -= 6;
      // }
      // this.weekendDays = [prevLastWeekDay, lastWeekDay];
      this.weekendDays = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
    });

    this.checkPeriodContextChange();
    this.calendarId = this.router.url.split('?')[0];
    if (this.calendarId.indexOf('/list') > -1) {
      this.calendarId = this.calendarId.split('/list')[0];
    }
    this.store.dispatch(new CalendarChangeViewTypeAction(this.calendarId, this.viewType));
    this.selectedCalendarViewType = this.viewType;

    if (this.cellColors[this.cellColor] === undefined) {
      throw new Error(`Not found cellcolor: ${this.cellColor} in ${JSON.stringify(this.cellColors)}`);
    }
    if (isNil(this.setHeaderDayNumberCssClassFn)) {
      throw new Error('setHeaderDayNumberCssClassFn is null!');
    }
    if (isNil(this.titleIcon)) {
      throw new Error('Not found title icon');
    }
  }

  private getRemoteData() {
    const events$ = this._refresh$.pipe(
      startWith(undefined),
      switchMap(() => {
        if (isFunction(this.databaseCallback)) {
          return this.databaseCallback(
            this.database,
            { active: 'id', direction: 'asc' },
            {
              pageIndex: 0,
              pageSize: 999999,
              length: 1,
            },
            ''
          );
        } else {
          return this.database.getAll(
            { active: 'id', direction: 'asc' },
            {
              pageIndex: 0,
              pageSize: 999999,
              length: 1,
            },
            ''
          );
        }
      }),
      map((result: any) => {
        this.showLoading();
        result = (result as unknown) as { data: any[]; options: { days: any[]; start_date: string } };
        this.options = result.options;
        // azert kell, hogy az options input hamarabb feltoltodjon
        this.cdr.markForCheck();
        if (this.hasSpecialDaysLayer === true) {
          this.calendarUtils.startDate = result.options.start_date;
          this.calendarOptions = result.options;
        }
        this.calendarUtils.endDate = result.options.end_date;

        const a = moment(`${result.options.start_date} 00:00`, 'YYYY-MM-DD HH:mm');
        const b = moment(`${result.options.end_date} 00:00`, 'YYYY-MM-DD HH:mm');
        const daysDiff = b.diff(a, 'days') + 1;
        return { events: this.fillCalendarCb(result.data), calendarRowCount: Math.ceil((daysDiff === 28 ? 31 : daysDiff) / 7) };
      }),
      tap(() => {
        this.hideLoading();
        if (!isNil(this.selectedTrips)) {
          (this.selectedTrips['selectionTripForm'].controls['selectionControls'] as FormArray).controls.forEach(group => {
            (group as FormGroup).controls['checked'].patchValue(false);
          });
        }
      }),
      catchError(error => {
        this.isLoading = false;
        MaybeHandleHttpError.maybeHandleHttpError(error);
        return of(error);
      })
    );

    if (this.viewType === RRCalendarViewType.CALENDAR) {
      this.events$ = events$;
    } else {
      this.listEvents$ = events$;
    }
  }

  showLoading() {
    this.isLoading = true;
    this.cdr.markForCheck();
  }

  hideLoading() {
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  refresh() {
    this._refresh$.next();
  }

  onClosedTopRightMenu(topRightMenuButtonToggleGroup: MatButtonToggleGroup): void {
    timer(0).subscribe(() => (topRightMenuButtonToggleGroup.value = undefined));
  }

  toggleViewType() {
    let route: string[];
    if (this.viewType === RRCalendarViewType.CALENDAR) {
      route = ['./list'];
    } else {
      route = ['./../'];
    }
    this.store.dispatch(
      new CalendarChangeViewTypeAction(
        this.calendarId,
        this.viewType === RRCalendarViewType.CALENDAR ? RRCalendarViewType.LIST : RRCalendarViewType.CALENDAR
      )
    );
    this.router.navigate(route, { relativeTo: this.route });
  }

  toggleViewTypeFn() {
    return this.toggleViewType.bind(this);
  }

  ngAfterViewInit() {
    this.getRemoteData();
    timer(500).subscribe(() => {
      const stickyElm = document.querySelector('.rr-calendar-header-row');

      if (!isNil(stickyElm)) {
        this.intersectionObserver = new IntersectionObserver(([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1), {
          root: document.querySelector('.mat-sidenav-content'),
          threshold: [1],
        });

        this.intersectionObserver.observe(stickyElm);
      }
    });
  }

  onSelectedChangeTrips(selectedTrips: FormGroup) {
    this.selectedTrips = selectedTrips;
  }

  onSelectedTripEvent(event: any) {
    this.selectedTripEvent = event;
  }

  onnRefreshCalendar() {
    this.getRemoteData();
  }

  onTripActionHasSuccess($event: Date): void {
    this.tripActionHasSuccess = $event;
  }

  changeCalenderViewToggleGroup(value: RRCalendarViewType): void {
    this.selectedCalendarViewType = value;
    this.toggleViewType();
  }
}
