import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { FingerprintService } from '../../fingerprint/fingerprint.service';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { RegistrationEnumType } from '../../../model/register-social.model';
import { AuthService } from '../../../auth.service';
import { UserModel } from '../../../model/user.model';

@Component({
  selector: 'rr-social-buttons-card',
  templateUrl: './social-buttons-card.component.html',
  styleUrls: ['./social-buttons-card.component.scss', './app-logo-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FingerprintService, CookieService],
})
export class SocialButtonsCardComponent {
  @Input() buttonColor = 'primary';
  @Input() buttonType = 'mat-stroked-button';
  @Input() form: FormGroup;
  @Input() withEmailButtonTranslateKey: string;
  @Input() withEmailIcon = 'email-outline';
  @Output() clickWithEmail = new EventEmitter<void>();
  @Input() registrationType = RegistrationEnumType.REG_TYPE_B;

  readonly registrationEnumType = RegistrationEnumType;
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService<UserModel>,
    private fingerprintService: FingerprintService
  ) {}

  onClickFacebookLogin() {
    this.form.disable();
    this.authService.loginWithFacebook(this.cookieService, this.fingerprintService.getCode());
  }

  onClickGoogleLogin() {
    this.form.disable();
    this.authService.loginWithGoogle(this.cookieService, this.fingerprintService.getCode());
  }

  onClickAppleLogin() {
    this.form.disable();
    this.authService.loginWithApple(this.cookieService, this.fingerprintService.getCode());
  }

  onClickWithEmail() {
    this.clickWithEmail.emit();
  }
}
