import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { CompanyContextState, STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { GetUserStatesSuccessAction, translocoLazyLoadScope } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { produce } from '@ngxs-labs/immer-adapter';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { CompleteIntroAction } from './action/complete-intro.action';
import { StartIntroAction } from './action/start-intro.action';
import { INTROJS_TYPES_ENUM, IntrojsConfigsInterface, IntrojsMock } from '../introjs-config.interface';
import { LoadedIntroAction } from './action/loaded-intro.action';
import { CallbackOptions, Step } from '../introjs.service';
import { switchMap, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { TranslocoService } from '@ngneat/transloco';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import type { Options } from 'intro.js';
import { HttpInterceptingHandler } from '../http-intercepting-handler';
import { environment } from '@roadrecord/environment';

export const INTROJS_STATE_VERSION = 1;

export interface IntrojsContextStateModel {
  showed: INTROJS_TYPES_ENUM[] | null;
  run: boolean;
  currentId: INTROJS_TYPES_ENUM | null;
  globalOptions?: Options | null;
  currentSteps: Step[] | null;
  currentCallbacks: CallbackOptions | null;
  mocks: IntrojsMock[] | null;
  version: number;
}

function _notShowedFilter(state: IntrojsContextStateModel, filter: INTROJS_TYPES_ENUM) {
  return state.showed === null || state.showed.indexOf(filter) === -1;
}

@State<IntrojsContextStateModel>({
  name: 'introContext',
  defaults: {
    showed: null,
    run: false,
    currentId: null,
    globalOptions: null,
    currentSteps: null,
    currentCallbacks: null,
    mocks: null,
    version: INTROJS_STATE_VERSION,
  },
})
@Injectable()
export class IntrojsState {
  private readonly key: string;
  private httpHandler: HttpInterceptingHandler;

  // @Selector()
  // static currentId(state: IntrojsContextStateModel): INTROJS_TYPES_ENUM {
  //   return state.currentId;
  // }

  @Selector()
  static showedCurrentId(state: IntrojsContextStateModel): boolean {
    return state.showed.some(showed => showed === state.currentId);
  }

  @Selector()
  static globalOptions(state: IntrojsContextStateModel): Options | null {
    return state.globalOptions;
  }

  @Selector()
  static currentSteps(state: IntrojsContextStateModel): Step[] {
    return state.currentSteps;
  }

  @Selector()
  static currentCallbacks(state: IntrojsContextStateModel): CallbackOptions {
    return state.currentCallbacks;
  }

  @Selector()
  static run(state: IntrojsContextStateModel): boolean {
    return state.run;
  }

  @Selector()
  static showed(state: IntrojsContextStateModel): INTROJS_TYPES_ENUM[] {
    return state.showed;
  }

  static notShowedFilter(filter: INTROJS_TYPES_ENUM) {
    return createSelector([IntrojsState], (state: IntrojsContextStateModel) => _notShowedFilter(state, filter));
  }

  constructor(
    injector: Injector,
    private http: HttpClient,
    private translocoService: TranslocoService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private store: Store,
    httpHandler: HttpHandler
  ) {
    this.httpHandler = httpHandler as HttpInterceptingHandler;
    this.key = `${injector.get<string>(STATE_PREFIX_TOKEN)}.intro`;
  }

  @Action(GetUserStatesSuccessAction)
  getUserStatesSuccess(ctx: StateContext<IntrojsContextStateModel>, action: GetUserStatesSuccessAction): void {
    if (isNil(action.states[this.key]) || isNil(action.states[this.key]) || !Array.isArray(action.states[this.key].showed)) {
      return;
    }
    produce<IntrojsContextStateModel>(ctx, draft => {
      const intro = action.states[this.key] as { showed: IntrojsContextStateModel['showed'] };
      draft.showed = intro.showed;
    });
  }

  @Action(StartIntroAction)
  startIntro(ctx: StateContext<IntrojsContextStateModel>, action: StartIntroAction) {
    if (this.store.selectSnapshot(CompanyContextState.isTrialTimeExpired) === true) {
      return;
    }
    const state = ctx.getState();
    const options = action.options;
    if (options.skipShowedCheck === true || (state.showed !== null && _notShowedFilter(state, action.id))) {
      const periodContextType =
        // Ha period context alapjan kell megjeleniteni az intro-t
        options.withPeriodContext === false
          ? undefined
          : this.store.selectSnapshot(this.periodContextStateSelectorsService.isCompany)
          ? 'company'
          : this.store.selectSnapshot(this.periodContextStateSelectorsService.isSelfEmployed)
          ? 'self-employed'
          : 'private';

      // extra esetek lekezelese
      let extraSuffix = '';
      let importInstance: Promise<any>;
      switch (action.id) {
        case INTROJS_TYPES_ENUM.APP_START:
          importInstance = import('../../../../app-start/src/lib/config');
          break;
        case INTROJS_TYPES_ENUM.MONTH_ACTIVITIES:
          importInstance = import('../../../../month-activities/src/lib/config');
          // #RROHU-2417 kezelese: ha el van rejtve a kezdo km ora allas menupont
          extraSuffix =
            this.store.selectSnapshot(states => states.menu.excluded).indexOf('MILAGE_OF_PREVIOUS_MONTH') > -1 &&
            ['company', 'self-employed'].indexOf(periodContextType) > -1
              ? '_WITHOUT_MILEAGE_OF_PREVIOUS_MONTH'
              : '';

          if (
            periodContextType === 'company' &&
            this.store.selectSnapshot(this.periodContextStateSelectorsService.context).vehicle.payoff_type ===
              0 /*circular dep miatt nem tudjuk hasznalni a PayoffTypeEnum-t*/
          ) {
            extraSuffix += '_MODE_SMR';
          } else if (
            periodContextType === 'company' &&
            this.store.selectSnapshot(this.periodContextStateSelectorsService.context).vehicle.payoff_type ===
              1 /*circular dep miatt nem tudjuk hasznalni a PayoffTypeEnum-t*/
          ) {
            extraSuffix += '_MODE_AEM';
          }
          break;
        case INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS:
          importInstance = import('../../../../finalization-of-route-details/src/lib/config');
          if (options.withPeriodContext === false) {
            extraSuffix = '/COMMON';
          }
          break;
      }

      const replacedExtraSuffix = extraSuffix.replace(/\//, '_');
      const moduleId =
        periodContextType !== undefined
          ? `${action.id}_${periodContextType.toUpperCase().replace(/-/, '_')}${replacedExtraSuffix}`
          : `${action.id}${replacedExtraSuffix}`;

      if (options.skipShowedCheck === true || (state.showed !== null && _notShowedFilter(state, moduleId as any))) {
        return translocoLazyLoadScope(
          this.translocoService,
          `intro/${action.id.toLowerCase()}${
            periodContextType !== undefined ? `/${periodContextType}` : ''
          }${extraSuffix.toLowerCase()}`.replace(new RegExp(/_/, 'g'), '-')
        ).pipe(
          switchMap(() => fromPromise(importInstance)),
          tap((module: IntrojsConfigsInterface) =>
            ctx.dispatch(
              new LoadedIntroAction(
                module[moduleId].steps,
                moduleId,
                module[moduleId].callbacks,
                module[moduleId].globalOptions,
                module[moduleId].mocks
              )
            )
          )
        );
      }
    }
  }

  @Action(LoadedIntroAction)
  loadedIntro(ctx: StateContext<IntrojsContextStateModel>, action: LoadedIntroAction) {
    produce<IntrojsContextStateModel>(ctx, draft => {
      draft.run = true;
      draft.currentId = action.id as INTROJS_TYPES_ENUM;
      draft.globalOptions = action.globalOptions as any;
      draft.currentSteps = action.steps as any;
      draft.currentCallbacks = action.callbacks;
      draft.mocks = action.mocks;
      this.httpHandler.mocks = action.mocks;
    });
  }

  @Action(CompleteIntroAction)
  completeIntro(ctx: StateContext<IntrojsContextStateModel>, action: CompleteIntroAction) {
    produce<IntrojsContextStateModel>(ctx, draft => {
      // remove duplications
      if (action.enableDontShowAgain) {
        draft.showed = [...new Set(draft.showed.concat(action.id))];
      } else {
        draft.showed = draft.showed.filter(id => id !== action.id);
      }
      draft.mocks = null;
      this.httpHandler.resetMocks();
      draft.run = false;
      draft.currentSteps = null;
      draft.currentCallbacks = null;
    });
    return this.http.put(`${environment.apiUrl}state/${this.key}`, { showed: ctx.getState().showed });
  }
}
