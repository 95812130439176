import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { PriceListComponent } from './list/list.component';
import { MatButtonModule } from '@angular/material/button';
import { OrderComponent } from './order/order.component';
import { OrderLayoutComponent } from './layout/layout.component';
import {
  CapitalizeDirectiveModule,
  FocusInputModule,
  IsNilPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ValidatingModule } from '@roadrecord/validating';
import { IMaskModule } from 'angular-imask';
import { FuelTypeModelByIdPipeModule, IsPrivateCarPipeModule } from '@roadrecord/vehicle/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentSummaryComponent } from './summary/summay.component';
import { MomentModule } from 'ngx-moment';
import { PaymentComponent } from './payment/payment.component';
import { GridModule } from '@roadrecord/grid';
import { DateFormatPipe } from './pipe/date-format.pipe';
import { FeatureTextFormatPipe } from './pipe/feature-text-format.pipe';
import { MatTableModule } from '@angular/material/table';
import { JsonDifferModule } from '@roadrecord/json-differ';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ItalicTextFormatPipe } from './pipe/italic-text.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';

export const priceRoutes: Route[] = [
  {
    path: '',
    component: OrderLayoutComponent,
  },
  {
    path: 'payment-response',
    component: PaymentComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(priceRoutes),
    MatButtonModule,
    IsNilPipeModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule,
    TranslocoModule,
    FlexModule,
    FormsModule,
    CapitalizeDirectiveModule,
    FocusInputModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidatingModule,
    IMaskModule,
    FuelTypeModelByIdPipeModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MomentModule,
    GridModule,
    SanitizePipeModule,
    IsPrivateCarPipeModule,
    MatTableModule,
    JsonDifferModule,
    NgxJsonViewerModule,
    MatSortModule,
    MatPaginatorModule,
    PreventClickModule,
    MatAutocompleteModule,
    MatExpansionModule,
  ],
  declarations: [
    DateFormatPipe,
    FeatureTextFormatPipe,
    ItalicTextFormatPipe,
    OrderLayoutComponent,
    PriceListComponent,
    OrderComponent,
    PaymentSummaryComponent,
    PaymentComponent,
  ],
  exports: [OrderLayoutComponent, PaymentComponent],
})
export class PriceModule {}
