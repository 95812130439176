import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { isNil, isNumeric } from '@roadrecord/type-guard';
import moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ScrollStateHandler } from './scroll-state.handler';
import { RRCalendarEvent } from '../../../model/rr-calendar-event';
import { DOCUMENT } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectedTripsEventTypeEnum } from '../../../model/salected-trips-event.enum';
import { SelectedTripActionModel } from '../../../model/selected-trips-event.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@roadrecord/environment';
import {
  BatchRequestModel,
  BatchRequestService,
  BatchResponseModel,
  handleErrorObjectGenerateDialogOptions,
  MaybeHandleHttpError,
} from '@roadrecord/utils';
import { SelectedTripsActionTypeEnum } from '../../../model/selected-trip-action-type.enum';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

interface ListEvent<E> {
  date: Date;
  events: E[];

  [key: string]: unknown;
}

@UntilDestroy()
@Component({
  selector: 'rr-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaComponent<E, D extends ListEvent<E>> implements OnChanges, OnDestroy {
  @Input() options: any;
  @Input() selectedTripEvent: any;
  @Input() listItemTitleAfterDateCellTemplateRef: TemplateRef<any>;
  @Input() listItemDescriptionCellTemplateRef: TemplateRef<any>;
  @Input() listItemCellTemplateRef: TemplateRef<any>;
  @Input() readonly calendarId: string;
  @Output() readonly openEvent = new EventEmitter<{ date: Date; events: RRCalendarEvent[] }>();
  @Output() readonly selectedTripChangeEvent = new EventEmitter<{ selectionTripForm: FormGroup }>();
  @Output() readonly refreshCalenderEvent = new EventEmitter();
  @Output() readonly tripActionHasSuccess = new EventEmitter<Date>();

  @HostBinding('class.disabled')
  disabled = false;
  selectionTripForm = new FormGroup({
    selectedCount: new FormControl(0),
    selectionControls: new FormArray([]),
  });
  @Input() listEvents: D[] | null = null;
  private scrollStateHandler: ScrollStateHandler | null = null;
  constructor(
    private store: Store,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private batchRequestService: BatchRequestService,
    private messageDialogService: MessageDialogService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  readonly trackByFn = (index, item: { events: RRCalendarEvent[] }) => {
    return `${item.events.reduce((prev, current) => `${prev}${current.start}`, '')}_${this.translocoService.getActiveLang()}`;
  };

  ngOnDestroy() {
    this.scrollStateHandler.ngOnDestroy();
  }

  editEvents(day: { date: Date; events: RRCalendarEvent[] }): void {
    if (this.disabled === true) {
      return;
    }

    this.disabled = true;
    this.openEvent.emit({ date: day.date, events: day.events });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['listEvents'])) {
      if (!isNil(changes['listEvents'].currentValue)) {
        let controlIndex = 0;
        (this.selectionTripForm.controls['selectionControls'] as FormArray).clear();

        const listEvents: D[] = [];
        const value = changes['listEvents'].currentValue;
        const minViewDate = moment(this.options.start_date, 'YYYY-MM-DD HH:mm');
        const maxViewDate = moment(this.options.end_date, 'YYYY-MM-DD HH:mm').add('days', 1);

        while (minViewDate < maxViewDate) {
          const foundDay: any = this.searchDayInList((value as any).events, minViewDate.format('YYYY-MM-DD'));
          const events = foundDay.events;
          const selectedDay = foundDay.date;

          delete foundDay.events;
          delete foundDay.date;

          listEvents.push({
            date: minViewDate.toDate(),
            events,
            ...foundDay,
          });
          minViewDate.add(1, 'days');

          events.forEach(event => {
            Object.assign(event, { controlIndex: controlIndex });
            (this.selectionTripForm.controls['selectionControls'] as FormArray).push(
              this.fb.group({
                trip_id: new FormControl(event.id),
                date: new FormControl(selectedDay),
                row_index: new FormControl(event.row_index),
                checked: new FormControl(false),
              })
            );
            controlIndex++;
          });
        }
        this.listEvents = listEvents;

        if ((this.selectionTripForm.controls['selectionControls'] as FormArray).length > 0) {
          this.selectionTripForm.controls['selectionControls'].valueChanges.subscribe(selectedTrips => {
            const selectedCount = selectedTrips.reduce((next: number, current: any) => next + current.checked, 0);
            this.selectionTripForm.controls['selectedCount'].patchValue(selectedCount, { emitEvent: false });
            this.selectedTripChangeEvent.emit({ selectionTripForm: this.selectionTripForm });
          });
        }

        if (this.scrollStateHandler === null) {
          this.scrollStateHandler = new ScrollStateHandler(this.store, this.calendarId, this.document, this.ngZone);
        }

        if (this.ngZone.isStable) {
          this.scrollStateHandler.start();
        } else {
          this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.scrollStateHandler.start());
        }
      }
    } else if (!isNil(changes['selectedTripEvent']) && !isNil(changes['selectedTripEvent'].currentValue)) {
      const tripEvent: SelectedTripActionModel = changes['selectedTripEvent'].currentValue;

      // Floating window bezárása kijelölések megszüntetése
      if (tripEvent.event === SelectedTripsEventTypeEnum.SAVE) {
        // Floating window bezárása adatok mentése
        const selectedTrips = (this.selectionTripForm.controls['selectionControls'] as FormArray).controls
          .filter(control => control.get('checked').value === true)
          .map(selected => {
            return {
              date: selected.get('date').value,
              row_index: selected.get('row_index').value,
              trip_id: selected.get('trip_id').value,
            };
          });

        switch (tripEvent.actionType) {
          case SelectedTripsActionTypeEnum.TRIP_REASON:
            this.batchRequestService.start();
            selectedTrips.forEach(item => {
              this.batchRequestService.add(this.destinationTripReasonChangeBatch(item.date, item.row_index, tripEvent.trip_reason_id));
            });
            this.batchRequestService.end().subscribe(
              responses => {
                this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_SUCCESS'));
                this.refreshCalenderEvent.emit();
                this.tripActionHasSuccess.emit(new Date());
              },
              error => {
                MaybeHandleHttpError.maybeHandleHttpError(error);
              }
            );
            break;
          case SelectedTripsActionTypeEnum.PARTNER:
            this.batchRequestService.start();
            selectedTrips.forEach(trip => {
              this.batchRequestService.add(this.partnerChangeBatch(trip.date, trip.row_index, tripEvent.partner_id));
            });
            this.batchRequestService.end().subscribe(
              responses => {
                this.showFloatingActionResponse(responses);
                this.refreshCalenderEvent.emit();
                this.tripActionHasSuccess.emit(new Date());
              },
              error => {
                MaybeHandleHttpError.maybeHandleHttpError(error);
              }
            );
            break;
          case SelectedTripsActionTypeEnum.DELETE:
            this.batchRequestService.start();
            selectedTrips.forEach(item => {
              this.batchRequestService.add(this.tripDeleteBatch(item.trip_id));
            });
            this.batchRequestService.end().subscribe(
              () => {
                this.refreshCalenderEvent.emit();
                this.tripActionHasSuccess.emit(new Date());
              },
              error => {
                MaybeHandleHttpError.maybeHandleHttpError(error);
              }
            );
            break;
        }
      }
    }
  }

  /**
   * Partner módosítás batch
   * @param date {string}
   * @param rowIndex {number}
   * @param partner_id {number}
   * @private
   */
  private partnerChangeBatch(date: string, rowIndex: number, partner_id: number): BatchRequestModel {
    return {
      method: 'put',
      relative_url: `/${environment.baseApiEndpoint}/destination/row-update/${date}/${rowIndex}/`,
      body: { partner_id: `${partner_id}` },
    };
  }

  /**
   * Utak törlése batch
   * @param id {number}
   * @private
   */
  private tripDeleteBatch(id: number): BatchRequestModel {
    return {
      method: 'delete',
      relative_url: `/${environment.baseApiEndpoint}/destination/${id}/`,
      body: undefined,
    };
  }

  /**xw
   * Partner módosítás batch
   * @param date {string}
   * @param rowIndex {number}
   * @param partner_id {number}
   * @private
   */
  private destinationTripReasonChangeBatch(date: string, rowIndex: number, trip_reason_id: number): BatchRequestModel {
    return {
      method: 'put',
      relative_url: `/${environment.baseApiEndpoint}/destination/row-update/${date}/${rowIndex}/`,
      body: { trip_reason_id: `${trip_reason_id}` },
    };
  }

  private searchDayInList(list: any[], minViewDateString: string) {
    const foundDay = list.find(entity => entity.date === minViewDateString);
    return foundDay === undefined ? { events: [] } : foundDay;
  }

  private showFloatingActionResponse(responses: any): void {
    const errorRows = responses.filter((response: { code: number }) => ![200, 204].includes(response.code));

    if (errorRows.length > 0) {
      const errorTable = `<table><tbody>
                  ${errorRows
                    .map(
                      row =>
                        `<tr>
                              <td>${row.body.detail}</td>
                        </tr>`
                    )
                    .join('')}</tbody></table>`;

      this.messageDialogService.open(
        {
          id: null,
          type: errorTable.length > 0 ? MessageDialogTypeEnum.WARNING : MessageDialogTypeEnum.INFORMATION,
          text: errorTable,
          htmlMode: true,
          translateText: false,
        },
        {
          panelClass: ['with-table-content', 'has-scroll-table', 'responsive-error-dialog'],
        }
      );
    } else {
      this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_SUCCESS'));
    }
  }
}
