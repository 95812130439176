import { CallbackOptions, Step } from './introjs.service';
import { Options } from 'intro.js';

export interface IntrojsMock {
  url: string | ((calledUrl: string) => string);
  body: unknown | (() => unknown);
  statusCode: number;
}

export interface IntrojsConfigInterface {
  globalOptions?: Options;
  steps: Step[];
  callbacks?: CallbackOptions;
  mocks?: IntrojsMock[];
}

export enum INTROJS_TYPES_ENUM {
  // This is not real intro, just a flag to control intro to after first stepper
  AFTER_FIRST_STEPPER = 'AFTER_FIRST_STEPPER',
  APP_START = 'APP_START',
  MONTH_ACTIVITIES = 'MONTH_ACTIVITIES',
  MONTH_ACTIVITIES_COMPANY = 'MONTH_ACTIVITIES_COMPANY',
  MONTH_ACTIVITIES_PRIVATE = 'MONTH_ACTIVITIES_PRIVATE',
  MONTH_ACTIVITIES_SELF_EMPLOYED = 'MONTH_ACTIVITIES_SELF_EMPLOYED',
  FINALIZATION_OF_ROUTE_DETAILS = 'FINALIZATION_OF_ROUTE_DETAILS',
  FINALIZATION_OF_ROUTE_DETAILS_COMMON = 'FINALIZATION_OF_ROUTE_DETAILS_COMMON',
  FINALIZATION_OF_ROUTE_DETAILS_PRIVATE = 'FINALIZATION_OF_ROUTE_DETAILS_PRIVATE',
  FINALIZATION_OF_ROUTE_DETAILS_SELF_EMPLOYED = 'FINALIZATION_OF_ROUTE_DETAILS_SELF_EMPLOYED',
}

export interface IntrojsConfigsInterface {
  [INTROJS_TYPES_ENUM.APP_START]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_COMPANY]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_PRIVATE]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_SELF_EMPLOYED]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_COMMON]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_PRIVATE]: IntrojsConfigInterface;
  [INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_SELF_EMPLOYED]: IntrojsConfigInterface;
}
