import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { MessageDialogOptionsInterface, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { FINALIZATION_OF_ROUTE_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH } from '@roadrecord/common/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, timer } from 'rxjs';
import { RecommendationContinueAction } from '../state/action/recommendation-continue.action';
import { RecommendationFinishSuccessAction } from '../state/action/recommendation-finish-success.action';
import { RecommendationPayOfTypeEnum } from '../state/model/recommendation-pay-of-type.enum';
import { RecommendationStatusEnum } from '../state/model/recommendation-status.enum';
import { RecommendationState } from '../state/recommendation.state';
import { baseTable, getRowTpl } from './recommendation-helpers.function';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { isPrivatePeriodContextFn } from '../../is-private-period-context';

@UntilDestroy()
@Component({
  selector: 'rr-recommendation',
  templateUrl: './recommendation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationComponent {
  @Select(RecommendationState.hasCheckErrorOrWarning)
  hasCheckError$: Observable<boolean>;

  continueProcess = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private actions: Actions,
    private router: Router,
    private translocoService: TranslocoService,
    private messageDialogService: MessageDialogService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {
    this.subscribeUIStateActions();
  }

  private subscribeUIStateActions(): void {
    this.actions.pipe(ofActionSuccessful(RecommendationFinishSuccessAction), untilDestroyed(this)).subscribe(action => {
      const isPrivatePeriodContext = isPrivatePeriodContextFn(
        this.store.selectSnapshot(this.periodContextStateSelectorsService.context),
        this.periodContextStateSelectorsService
      );
      const dialogOptions: MessageDialogOptionsInterface = { id: null, text: undefined, htmlMode: true, translateText: false };
      const matDialogOptions: MatDialogConfig = { width: '800px', maxHeight: '95vh' };
      const dialogCb = () =>
        timer(0).subscribe(() => this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`]));

      let title: string;
      let content: string;
      let table: string;

      switch (action.response.status) {
        case RecommendationStatusEnum.OK:
          dialogOptions.type = MessageDialogTypeEnum.INFORMATION;
          if (action.response.pay_of_type === RecommendationPayOfTypeEnum.DISTANCE) {
            title = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_DISTANCE.TITLE';
            content = '';
            table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
              '%%ROWS%%',
              action.response.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
            );
            matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
          } else if (action.response.pay_of_type === RecommendationPayOfTypeEnum.COST) {
            title = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_COST.TITLE';
            content = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_COST.CONTENT';
            table = '';
          }
          dialogOptions.title = title;
          content = content.length > 0 ? this.translocoService.translate(content, { content: action.response.content }) : '';
          dialogOptions.text = `<p>${content}</p>${table}`;
          break;
        case RecommendationStatusEnum.WARNING:
          dialogOptions.type = MessageDialogTypeEnum.INFORMATION;
          if (isPrivatePeriodContext) {
            title = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.PRIVATE_PERIOD_CONTEXT.TITLE';
            content = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.PRIVATE_PERIOD_CONTEXT.CONTENT';
            table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
              '%%ROWS%%',
              action.response.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
            );
            matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
          } else {
            title = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.COMPANY_PERIOD_CONTEXT.TITLE';
            content = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.COMPANY_PERIOD_CONTEXT.CONTENT';
            table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
              '%%ROWS%%',
              action.response.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
            );
            matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
          }
          dialogOptions.title = title;
          dialogOptions.text = `<p>${this.translocoService.translate(content, { content: action.response.content })}</p>${table}`;
          break;
        case RecommendationStatusEnum.ERROR:
          dialogOptions.type = MessageDialogTypeEnum.WARNING;
          if (isPrivatePeriodContext) {
            title = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.PRIVATE_PERIOD_CONTEXT.TITLE';
            content = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.PRIVATE_PERIOD_CONTEXT.CONTENT';
            table = baseTable(this.translocoService, !isPrivatePeriodContext, false).replace(
              '%%ROWS%%',
              action.response.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext, false)).join('')
            );
            matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
          } else {
            title = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.COMPANY_PERIOD_CONTEXT.TITLE';
            content = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.COMPANY_PERIOD_CONTEXT.CONTENT';
            const is_fueling_range_correctable = action.response.table.some(row => row.hasOwnProperty('is_fueling_range_correctable'));

            table = baseTable(this.translocoService, !isPrivatePeriodContext, false, is_fueling_range_correctable).replace(
              '%%ROWS%%',
              action.response.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext, false)).join('')
            );
            matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
          }
          dialogOptions.title = title;
          dialogOptions.text = `<p>${this.translocoService.translate(content, {
            content: action.response.content,
          })}
            </p>${table}<br/>${this.translocoService.translate('RECOMMENDATION.FINISH_RESPONSE.ERROR.COMMON.WARNING_TEXT')}`;
          break;
      }

      if (dialogOptions.type !== undefined) {
        this.messageDialogService.open(dialogOptions, matDialogOptions).afterClosed().subscribe(dialogCb);
      } else {
        // RecommendationStatusEnum.OK eseten rogton navigalunk
        this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`]);
      }
    });
  }

  onContinueProcess(): void {
    this.continueProcess = true;
    this.cdr.markForCheck();
    this.store.dispatch(new RecommendationContinueAction());
  }
}
