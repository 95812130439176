import { Component } from '@angular/core';
import { fadeInAnimation } from '@roadrecord/common/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PricePaymentSessionStorageEnum } from '@roadrecord/user/common';
import { isNil } from '@roadrecord/type-guard';
import { Router } from '@angular/router';
import { CompanyContextState, goToOrderInANewTab } from '@roadrecord/company-context/common';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { AppTypeEnum, environment } from '@roadrecord/environment';

enum TrialTimeExpiredLayoutEnum {
  EXPIRED = 0,
  PRICE_LIST = 1,
  PAYMENT = 2,
}

@Component({
  selector: 'rr-expired-trial-time',
  templateUrl: './trial-time-expired.component.html',
  styleUrls: ['./trial-time-expired.component.scss'],
  animations: [fadeInAnimation],
})
export class TrialTimeExpiredComponent {
  readonly TrialTimeExpiredLayoutEnum = TrialTimeExpiredLayoutEnum;
  readonly isHUAppType = environment.appType === AppTypeEnum.HU;
  stepperPriceLayout: TrialTimeExpiredLayoutEnum = TrialTimeExpiredLayoutEnum.EXPIRED;

  constructor(
    private store: Store,
    private http: HttpClient,
    private readonly router: Router,
    private gtmService: GoogleTagManagerService,
    private translocoService: TranslocoService
  ) {
    if (!isNil(sessionStorage.getItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_IN_TRIAL_TIME))) {
      this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.PAYMENT;
    } else {
      this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.EXPIRED;
    }
  }

  onBackToExpiredForm(): void {
    this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.EXPIRED;
  }

  onBackToPayment(success: boolean): void {
    if (success === true) {
      if (isNil(sessionStorage.getItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_IN_TRIAL_TIME)) === true) {
        this.router.navigate(['/']);
      } else {
        this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.EXPIRED;
      }
    } else {
      this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.PRICE_LIST;
    }
  }

  goToPriceList() {
    this.gtmService.pushTag({
      event: `Click GO_TO_PRICE_LIST`,
      label: 'FirstWelcomeChoiceClick',
      category: 'FirstStepper',
    });
    if (this.isHUAppType === true) {
      goToOrderInANewTab(this.store.selectSnapshot(CompanyContextState.subscriptionState), this.http, this.translocoService);
    } else {
      this.stepperPriceLayout = TrialTimeExpiredLayoutEnum.PRICE_LIST;
      sessionStorage.setItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_IN_TRIAL_TIME, 'true');
    }
  }
}
