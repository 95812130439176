import { transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { simpleFadeInAnimation, simpleFadeOutAnimation, slideBottomOutAnimateSteps, WINDOW } from '@roadrecord/common/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { FirstStepsService } from '../first-steps.service';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { CommonWrapperComponent } from './common-wrapper.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

const logScopeName = 'StepperWrapperComponent';

@Component({
  selector: 'rr-wrapper, rr-first-steps-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  animations: [
    simpleFadeInAnimation,
    simpleFadeOutAnimation,
    trigger('slideBottomOut', [transition('* => *', slideBottomOutAnimateSteps)]),
  ],
})
export class WrapperComponent extends CommonWrapperComponent implements OnDestroy {
  constructor(
    firstStepsService: FirstStepsService,
    route: ActivatedRoute,
    router: Router,
    translocoService: TranslocoService,
    @Inject(WINDOW) window: Window,
    store: Store,
    actions$: Actions,
    messageDialogService: MessageDialogService,
    cdr: ChangeDetectorRef,
    gtmService: GoogleTagManagerService,
    loadableService: LoadableService,
    @Inject(DOCUMENT) document: Document,
    renderer: Renderer2,
    protected readonly http: HttpClient
  ) {
    super(
      firstStepsService,
      route,
      router,
      translocoService,
      window,
      store,
      actions$,
      messageDialogService,
      cdr,
      gtmService,
      loadableService,
      document,
      renderer,
      http
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
