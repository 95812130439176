import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import { IsFunctionPipeModule, PreventClickModule } from '@roadrecord/common/common';
import { MessageDialogModule } from '@roadrecord/message-dialog';
import { RightClickMenuModule } from '@roadrecord/right-click-menu';
import { CalendarDateFormatter, CalendarModule as AngularCalendarModule, CalendarUtils, DateAdapter } from 'angular-calendar';
import { MomentModule } from 'ngx-moment';
import { CalendarComponent } from './calendar/calendar.component';
import { momentAdapterFactory } from './calendar/moment-adapter.factory';
import { RRCalendarUtils } from './calendar/rr-calendar-utils';
import { RRCalendarHeaderDateFormatter } from './rr-calendar-header-date-formatter';
import { CalendarState } from './state/calendar.state';
import { CalendarComponent as CalendarViewComponent } from './calendar/view-types/calendar/calendar.component';
import { AgendaComponent } from './calendar/view-types/agenda/agenda.component';
import { DateFormatPipe } from './calendar/view-types/agenda/date-format.pipe';
import { SplitButtonModule } from '@roadrecord/split-button';
import { AddDefaultTopRightSplitButtonActionsPipe } from './pipe/add-default-top-right-split-button-actions.pipe';
import { FloatingActionComponent } from './calendar/floating-action/floating-action.component';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LiveAutoCompleteModule as PartnerLiveAutoCompleteModule } from '@roadrecord/partner/live-auto-complete';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import {
  ModalWrapperModule as PartnerModalWrapperModule,
  PARTNER_EDIT_FRAGMENT_PRESENTERS,
  PARTNER_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/partner/common';
import {
  ModalWrapperModule as TripReasonModalWrapperModule,
  TRIP_REASON_EDIT_FRAGMENT_PRESENTERS,
  TRIP_REASON_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/trip-reason/common';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    PreventClickModule,
    AngularCalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgxsModule.forFeature([CalendarState]),
    RightClickMenuModule,
    IsFunctionPipeModule,
    MatRippleModule,
    MessageDialogModule,
    MomentModule,
    MatProgressSpinnerModule,
    SplitButtonModule,
    LiveAutoCompleteModule,
    MatButtonToggleModule,
    LiveAutoCompleteModule,
    PartnerLiveAutoCompleteModule,
    PartnerModalWrapperModule,
    TripReasonModalWrapperModule,
    FragmentPresenterModule.forFeature([
      ...PARTNER_NEW_FRAGMENT_PRESENTERS,
      ...PARTNER_EDIT_FRAGMENT_PRESENTERS,
      ...TRIP_REASON_NEW_FRAGMENT_PRESENTERS,
      ...TRIP_REASON_EDIT_FRAGMENT_PRESENTERS,
    ]),
  ],
  declarations: [
    CalendarComponent,
    CalendarViewComponent,
    AgendaComponent,
    DateFormatPipe,
    AddDefaultTopRightSplitButtonActionsPipe,
    FloatingActionComponent,
  ],
  exports: [CalendarComponent, AngularCalendarModule, AgendaComponent],
  providers: [
    // {
    //   provide: MOMENT,
    //   useValue: moment
    // },
    {
      provide: CalendarDateFormatter,
      useClass: RRCalendarHeaderDateFormatter,
    },
    {
      provide: CalendarUtils,
      useClass: RRCalendarUtils,
    },
  ],
})
export class CalendarModule {}
