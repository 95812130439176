import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { isBoolean, isEmptyString, isFunction, isNil, isNumeric, isObject, isString } from '@roadrecord/type-guard';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, race, Subscription, timer } from 'rxjs';
import { delay, filter, map, startWith, take } from 'rxjs/operators';
import { AbstractEntityService, commonHttpStreamErrorHandler, ENTITY_SERVICE_TOKEN, HttpListResponseModel } from '@roadrecord/utils';
import { Sort, SortDirection } from '@angular/material/sort';
import { LiveAutoCompleteBase } from './live-auto-complete.base';
import { MatSelect } from '@angular/material/select';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { deepEqual, WINDOW } from '@roadrecord/common/common';
import { LIVE_AUTO_COMPLETE_LAZY_OPTION_ELEM_OPTIONS_MODEL_KEY } from '../model/model-key.symbol';
import { LiveAutoCompleteLazyCmpInterface } from '../model/live-auto-complete-lazy-cmp.interface';
import { LiveAutoCompleteLazyOptionElemConfig } from '../model/live-auto-complete-lazy-option-elem.config';
import { LiveAutocompleteOptionClassesBindFunction } from '../function-type/function/live-autocomple-option-classes-bind.function';
import { LiveAutoCompleteOptionsConfigModel } from '../model/live-auto-complete-options-config.model';
import { fadeOut2XFastLeave } from '@roadrecord/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidationMessageModel } from '@roadrecord/validating';
import { FragmentHideDialogAction, FragmentRemoveDialogAction, FragmentShowDialogAction } from '@roadrecord/fragment-dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { FloatLabelType, MatOption } from '@angular/material/core';
import { RawValue } from '../raw-value.directive';
import { markSelectedText } from '../mark-selected-text.function';

const logScopeName = 'LiveAutoCompleteComponent';

@UntilDestroy()
// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'rr-live-auto-complete',
  templateUrl: './live-auto-complete.component.html',
  styleUrls: ['./live-auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeOut2XFastLeave],
})
export class LiveAutoCompleteComponent<DATA_MODEL> extends LiveAutoCompleteBase<DATA_MODEL> implements OnInit, OnChanges {
  @ViewChildren('newMatOptionButton') newMatOptionButton: QueryList<MatOption>;
  @ViewChildren('notFoundMatOption') notFoundMatOption: QueryList<MatOption>;
  markSelectedText = markSelectedText;
  @ViewChild(MatSelect, { static: true })
  readonly matSelect: MatSelect;
  @ViewChild('ngxMatSelectSearch', { static: true })
  readonly ngxMatSelectSearch: { adjustScrollTopToFitActiveOptionIntoView: () => void };
  @Input() floatLabel: FloatLabelType;
  @Input() hideRequiredMarker: boolean;
  @Input() readonly formFieldClass: string;
  @Input() readonly label: string;
  @Input() readonly name: string;
  @Input() readonly notFoundEntityLabel = 'LIVE_AUTO_COMPLETE.NOT_FOUND_RESULTS';
  @Input()
  readonly placeholder: string;
  @Input()
  readonly optionTplRef: TemplateRef<any>;
  @Input()
  readonly optionClassFn: LiveAutocompleteOptionClassesBindFunction<DATA_MODEL>;
  /**
   * remote rendezes iranya
   */
  @Input()
  readonly sortDirection: SortDirection = 'asc';
  @Input()
  readonly database: AbstractEntityService<HttpListResponseModel<DATA_MODEL>, DATA_MODEL>;
  /**
   * Ha nincs megadva database, akkor kotelezo az entity service-t megadni!
   */
  @Input()
  entityService?: AbstractEntityService<HttpListResponseModel<DATA_MODEL>, DATA_MODEL>;
  @Input()
  readonly databaseCallback: (sort: Sort, page: PageEvent, simpleAllFilterValue: string) => Observable<HttpListResponseModel<DATA_MODEL>>;
  @Input() readonly formControlRef: FormControl = new FormControl();
  @Input() newFragmentRef: any;
  @Input() editFragmentRef: any;
  @Output() readonly hasOneCheckResponse = new EventEmitter<HttpListResponseModel<any>>();
  /**
   * akkor kell ha new -ra kattintast kivulrol akarjak vezerlni, ergo ezzel kikapcsoljuk a belso default mukodest
   */
  @Output() readonly clickNew = new EventEmitter<string>();
  @Output() readonly clickEdit = new EventEmitter<string>();
  @Output() readonly openNew = new EventEmitter<string>();
  @Output() readonly openEdit = new EventEmitter<string>();
  @Output() readonly openSearchPanel = new EventEmitter<void>();
  @Output() readonly selectionChange = new EventEmitter<DATA_MODEL>();
  @Input() @HostBinding('class.has-actions-menu') hasActionsMenu = true;
  @HostBinding('class.show-progress-spinner')
  showProgressSpinnerHostClass = false;
  remoteConfig: PageEvent & { hasNextDataPage: boolean; scrollFilterValue: string };
  readonly showProgressSpinner$ = new BehaviorSubject(false);
  readonly insideFormControl = new FormControl();
  defaultCompareFn = this.defaultCompare.bind(this);
  private hasOneCheck$: Subscription;
  /**
   * akkor hasznaljuk, az lazy option elemmel dolgozzunk, elso megjelenitett elem utan toltodik fel
   */
  private lazyCmp: LiveAutoCompleteLazyCmpInterface;
  private disableNextFormControlRefValueChange = false;
  /**
   * Azert kell tarolni, mert eloforuldhat hogy a 'disableNextFormControlRefValueChange' flag-et atbillentjuk,
   * viszont kivulrol hamarabb valtozik a 'formControlRef', igy a FormControl-ok osszekotese bukna, viszont ha letaroljuk
   * a flag-hez az erteket is akkor tudunk vizsgalodni...
   */
  private disableNextFormControlRefValueChangeValue: unknown;

  constructor(
    cdr: ChangeDetectorRef,
    @Attribute('classList') public classList: string,
    private store: Store,
    ngZone: NgZone,
    @Inject(WINDOW) window: Window,
    @Optional() @Inject(ENTITY_SERVICE_TOKEN) private injectedEntityService: unknown,
    private actions$: Actions
  ) {
    super(cdr, ngZone, window);

    if (isNil(this.classList)) {
      this.classList = '';
    }

    this.classList = `${this.classList}${this.classList.length > 0 ? ' ' : ''}mat-select`;
    if (this.classList.indexOf('live-auto-complete-result-panel') === -1) {
      this.classList = `live-auto-complete-result-panel ${this.classList}`;
    }

    this.showProgressSpinner$.subscribe(v => (this.showProgressSpinnerHostClass = v));
  }

  private _validationMessages: ValidationMessageModel[];

  get validationMessages(): ValidationMessageModel[] {
    const optionsValidationMessages =
      !isNil(this.optionsConfig) && Array.isArray(this.optionsConfig.validatorMessages) ? this.optionsConfig.validatorMessages : [];

    return [...(Array.isArray(this._validationMessages) ? this._validationMessages : []), ...optionsValidationMessages];
  }

  @Input()
  set validationMessages(value: ValidationMessageModel[]) {
    this._validationMessages = value;
  }

  private _selectedValue: DATA_MODEL;

  get selectedValue(): DATA_MODEL {
    return this._selectedValue;
  }

  set selectedValue(value: DATA_MODEL) {
    this._selectedValue = value;
  }

  private _hasOneCheckLazyDelay: number;

  get hasOneCheckLazyDelay(): number {
    return this._hasOneCheckLazyDelay;
  }

  @Input()
  set hasOneCheckLazyDelay(value: number) {
    this._hasOneCheckLazyDelay = coerceNumberProperty(value);
  }

  /**
   * ha be van kapcsolva akkor onInit-ben inditjuk amennyiben nincs meg kijelolt ertek
   * (amikor akkor lehet ha control-t ertekkel hozzak letre)
   */
  private _hasOneCheck = false;

  @Input()
  get hasOneCheck(): boolean {
    return this._hasOneCheck;
  }

  set hasOneCheck(value: boolean) {
    this._hasOneCheck = coerceBooleanProperty(value);
  }

  /**
   * when has selected element display mat prefix
   */
  private _hasPrefix = false;

  @Input()
  get hasPrefix(): boolean {
    return this._hasPrefix;
  }

  set hasPrefix(value: boolean) {
    this._hasPrefix = coerceBooleanProperty(value);
  }

  private _hasNewButton = false;

  @Input()
  get hasNewButton(): boolean {
    return this._hasNewButton;
  }

  set hasNewButton(value: boolean) {
    this._hasNewButton = coerceBooleanProperty(value);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _optionsConfig: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>;

  @Input()
  get optionsConfig(): LiveAutoCompleteOptionsConfigModel<DATA_MODEL> {
    return this._optionsConfig;
  }

  set optionsConfig(value: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>) {
    if (value instanceof LiveAutoCompleteOptionsConfigModel) {
      this._optionsConfig = value;
      if (!isNil(value.lazyOptionElem) && !isEmptyString(value.lazyOptionElem.overlayClass)) {
        this.classList += ` ${value.lazyOptionElem.overlayClass}`;
      }
    } else {
      throw new Error('Options config type missing LiveAutoCompleteOptionsConfigModel');
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (isNil(this._optionsConfig)) {
      throw new Error('Options config is required!');
    }
    if (isNil(this._optionsConfig.optionDisabledBindFn)) {
      throw new Error('Option disable bind is required!');
    }
    if (isNil(this._optionsConfig.optionDisplayFn)) {
      throw new Error('Option display bind is required!');
    }
    if (isNil(this._optionsConfig.displayFn)) {
      throw new Error('Display bind is required!');
    }
    if (isNil(this.name) || this.name.length === 0) {
      throw new Error('Name is required');
    }
    if (isNil(this.database) && isNil(this.databaseCallback)) {
      throw new Error('Database resource is required!');
    }
    if (isNil(this.entityService)) {
      this.entityService = this.injectedEntityService as any;
    }
    if (isNil(this.database) && isNil(this.entityService)) {
      throw new Error('Database or entity service is required!');
    }
    if (isNil(this.formControlRef)) {
      throw new Error('FormControlRef is required!');
    }

    // validatorok atvetele (TODO mi van ha dynamic valtoztatjak?)
    this.insideFormControl.setValidators(this.formControlRef.validator);
    this.insideFormControl.setAsyncValidators(this.formControlRef.asyncValidator);
    // set default value
    if (!isNil(this.formControlRef.value)) {
      this.overrideCurrentValue(this.formControlRef.value);
    }
    if (this.formControlRef.disabled) {
      this.insideFormControl.disable({ emitEvent: false });
    }
    this.formControlRef.statusChanges.subscribe(status => {
      if (this.formControlRef.touched === true && this.insideFormControl.touched === false) {
        this.insideFormControl.markAsTouched();
      } else if (this.formControlRef.touched === false && this.insideFormControl.touched === true) {
        this.insideFormControl.markAsUntouched();
        this.insideFormControl.setErrors(null);
      }

      if (this.formControlRef.dirty === true && this.insideFormControl.dirty === false) {
        this.insideFormControl.markAsDirty();
      } else if (this.formControlRef.dirty === false && this.insideFormControl.dirty === true) {
        this.insideFormControl.reset(this.insideFormControl.value);
      }

      if (status === 'DISABLED' && this.insideFormControl.enabled) {
        this.insideFormControl.disable({ emitEvent: false });
      } else if (status.indexOf('VALID') > -1 && this.insideFormControl.disabled) {
        this.insideFormControl.enable({ emitEvent: false });
      }
    });

    this.twoWayBindControls();

    this.classList += ` ${this.name}`;

    this.hasOneCheckGet();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes.optionTplRef) && !isNil(changes.optionTplRef.currentValue)) {
      this.classList += ' has-option-tpl';
    }
    if (
      !isNil(changes.optionsConfig) &&
      !isNil(changes.optionsConfig.currentValue) &&
      !isNil(changes.optionsConfig.currentValue.lazyOptionElem)
    ) {
      this.classList += ' has-lazy-option-elem';
      // ha valtozott a config akkor ujra bindoljuk a paramokat
      Promise.resolve().then(() => {
        /**
         * azert kell eltoni a kovi ciklus-ba, mert van ahol change detect hibat okoz,
         * pl: daily permanent destination-nel a nap lenyitasakor
         */
        const currentOptionList = this.list$.getValue();
        if (
          !deepEqual(changes.optionsConfig.currentValue, changes.optionsConfig.previousValue) &&
          Array.isArray(currentOptionList) &&
          currentOptionList.length > 0 &&
          !isNil(this.lazyCmp)
        ) {
          this.list$.getValue().forEach(item => this.lazyOptionElemFillInputs(item, changes.optionsConfig.currentValue.lazyOptionElem));
        }
      });
    }
  }

  lazyOptionElemInit($event, { options }: LiveAutoCompleteLazyOptionElemConfig, item: DATA_MODEL) {
    this.lazyCmp = $event.instance;
    this.lazyOptionElemFillInputs(item, options);
  }

  /**
   * megnezi hogy csak 1 egyed letezik, ha igen akkor az egyedet kivalasztja
   * hasOneCheckResponse output-ban jelzunk az eredmenyrol(http valasz)
   * @param overrideThisHasOneCheck ha kivulrol vagy nem normalis esetben hivjuk meg, tehat altalaban nem a this hivja meg
   */
  hasOneCheckGet(overrideThisHasOneCheck = false): void {
    if (
      (this._hasOneCheck || overrideThisHasOneCheck) &&
      (isNil(this.insideFormControl.value) || !isObject(this.insideFormControl.value))
    ) {
      this.runHasOneCheck = true;
      this.showProgressSpinner$.next(true);

      let obs = isFunction(this.databaseCallback)
        ? this.databaseCallback(
            { active: '', direction: this.sortDirection },
            {
              pageIndex: 0,
              pageSize: 2,
              length: 1,
            },
            ''
          )
        : this.database.getAll(
            {
              active: this.database.entityDefaultOrder,
              direction: this.sortDirection,
            },
            {
              pageIndex: 0,
              pageSize: 2,
              length: 1,
            },
            ''
          );
      if (isNumeric(this._hasOneCheckLazyDelay) && this._hasOneCheckLazyDelay > 0) {
        obs = obs.pipe(delay(this._hasOneCheckLazyDelay));
      }
      this.hasOneCheck$ = obs.pipe(untilDestroyed(this)).subscribe(
        response => {
          this.runHasOneCheck = false;
          if (Array.isArray(response.results) && response.results.length === 1) {
            this.formControlRef.setValue(response.results[0]);
            this.remoteConfig.hasNextDataPage = false;
          }
          this.showProgressSpinner$.next(false);
          timer(0).subscribe(() => this.hasOneCheckResponse.emit(response));
        },
        commonHttpStreamErrorHandler(() => {
          this.runHasOneCheck = false;
          this.showProgressSpinner$.next(false);
        })
      );
    }
  }

  onClickNewButton() {
    // check feature is enabled
    if (this._hasNewButton || this.hasActionsMenu) {
      const searchControlValue = this.searchFormControl.value;

      if (this.clickNew.observers.length > 0) {
        // kikapcsoljuk a belso mukodest, mivel van feliratkozo, ezert majd o kezeli
        this.clickNew.emit(searchControlValue);
      } else if (
        this.newFragmentRef !== undefined ||
        (this._optionsConfig.newOptions !== undefined && this._optionsConfig.newOptions.newFragment !== undefined)
      ) {
        this.store.dispatch(
          /*
          new FragmentShowDialogAction(this.newFragmentRef ? this.newFragmentRef : this._optionsConfig.newOptions.newFragment, undefined, {
            search: searchControlValue,
          })
*/
          new FragmentShowDialogAction(
            this.newFragmentRef ? this.newFragmentRef : this._optionsConfig.newOptions.newFragment,
            isBoolean(this._optionsConfig.newOptions.showTopRightCloseButton) && this._optionsConfig.newOptions.showTopRightCloseButton
              ? { showTopRightCloseButton: true }
              : undefined,
            {
              search: searchControlValue,
            }
          )
        );
      } else {
        console.warn(logScopeName, 'Not found new click observer and fragment dialog ref');
      }
      if (this.matSelect.panelOpen) {
        // panel becsukasa ha nyitva van
        timer(1000)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.matSelect.close());
      }
      if (this.openNew.observers.length > 0) {
        this.openNew.emit(searchControlValue);
      }
    }
  }

  onClickEditButton(selectedValue: DATA_MODEL = this.selectedValue, id?: number | string | any) {
    // check feature is enabled
    if (this.hasActionsMenu) {
      const searchControlValue = this.searchFormControl.value;

      if (this.clickEdit.observers.length > 0) {
        // kikapcsoljuk a belso mukodest, mivel van feliratkozo, ezert majd o kezeli
        this.clickEdit.emit(searchControlValue);
      } else if (
        this.editFragmentRef !== undefined ||
        (this._optionsConfig.editOptions !== undefined && this._optionsConfig.editOptions.editFragment !== undefined)
      ) {
        const dialogType = this.editFragmentRef
          ? this.editFragmentRef
          : isFunction(this._optionsConfig.editOptions.editFragment)
          ? this._optionsConfig.editOptions.editFragment(selectedValue)
          : this._optionsConfig.editOptions.editFragment;
        this.store.dispatch(
          /*
          new FragmentShowDialogAction(dialogType, undefined, {
            windowEditModelId: !isNil(id)
              ? isNumeric(id)
                ? id
                : id.id
              : isNumeric(this.insideFormControl.value)
              ? this.insideFormControl.value
              : this.insideFormControl.value.id,
          })
*/
          new FragmentShowDialogAction(
            dialogType,
            isBoolean(this._optionsConfig.newOptions.showTopRightCloseButton) && this._optionsConfig.newOptions.showTopRightCloseButton
              ? { showTopRightCloseButton: true }
              : undefined,
            {
              windowEditModelId: !isNil(id)
                ? isNumeric(id)
                  ? id
                  : id.id
                : isNumeric(this.insideFormControl.value)
                ? this.insideFormControl.value
                : this.insideFormControl.value.id,
            }
          )
        );
        race([
          this.actions$.pipe(
            ofActionSuccessful(FragmentHideDialogAction),
            filter(action => action.dialogType === dialogType)
          ),
          this.actions$.pipe(
            ofActionSuccessful(FragmentRemoveDialogAction),
            filter(action => action.dialogType === dialogType)
          ),
        ])
          .pipe(untilDestroyed(this), take(1))
          .subscribe(action => {
            if (action instanceof FragmentHideDialogAction && !isNil(action.data)) {
              this.formControlRef.patchValue(action.data);
            }
          });
      } else {
        console.warn(logScopeName, 'Not found edit click observer and fragment dialog ref');
      }
      if (this.matSelect.panelOpen) {
        // panel becsukasa ha nyitva van
        timer(1000)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.matSelect.close());
      }
      if (this.openEdit.observers.length > 0) {
        this.openEdit.emit(searchControlValue);
      }
    }
  }

  onSelectionChange() {
    const selectedValue = ((this.matSelect.selected as unknown) as RawValue<DATA_MODEL>).rawValue;
    if ((selectedValue as any) === this.newButtonRawValue) {
      this.onClickNewButton();
    } else {
      this._selectedValue = selectedValue;
      const value = (this.matSelect.selected as MatOption).value;
      this.disableNextFormControlRefValueChange = true;
      this.disableNextFormControlRefValueChangeValue = value;
      this.formControlRef.setValue(this.valueBind(selectedValue));
      this.selectionChange.emit(selectedValue);
      this.cdr.markForCheck();
    }
  }

  openPanel() {
    this.matSelect.open();
  }

  valueBind(item: DATA_MODEL) {
    return isFunction(this.optionsConfig.optionValueBindFn) ? this.optionsConfig.optionValueBindFn(item) : item;
  }

  defaultCompare(o1: unknown, o2: unknown) {
    if (isObject(o1) || isObject(o2)) {
      let service: AbstractEntityService<unknown, unknown>;
      if (!isNil(this.database)) {
        service = this.database;
      } else {
        service = this.entityService;
      }
      return service.getModelIdValue(o1) === service.getModelIdValue(o2);
    }
    return o1 === o2;
  }

  protected stopHasOneCheck(): void {
    if (this.runHasOneCheck && this.hasOneCheck$ !== undefined && !this.hasOneCheck$.closed) {
      this.runHasOneCheck = false;
      this.loading$.next(false);
      this.hasOneCheck$.unsubscribe();
    }
  }

  private twoWayBindControls() {
    this.formControlRef.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(value => {
          if (this.disableNextFormControlRefValueChange === true && deepEqual(this.disableNextFormControlRefValueChangeValue, value)) {
            this.disableNextFormControlRefValueChange = false;
            return false;
          }
          return true;
        })
      )
      .subscribe(value => this.overrideCurrentValue(value));
  }

  private overrideCurrentValue(value: DATA_MODEL) {
    if (deepEqual(this.insideFormControl.value, value)) {
      /**
       * Ha ugyan az az ertek mint elozoleg akkor eldobjuk a valtozasokat,
       * pl: Ha disabled lesz a control, akkor sajnos a disable-rol is kapunk ertesites ami nem jo nekunk ...
       */
      return;
    }
    this.resetRemoteConfig();
    this._selectedValue = value;
    const isEmpty = isNil(value) || (isString(value) && value.length === 0);
    this.list$.next(isEmpty ? [] : [value]);
    this.insideFormControl.setValue(isEmpty ? value : this.valueBind(value));
    this.formControlRef.setValue(this.insideFormControl.value, { emitEvent: false });
    this.cdr.markForCheck();
  }

  private lazyOptionElemFillInputs(item: DATA_MODEL, options: { [p: string]: any }) {
    // Model-t betoltjuk az input-ba
    const { inputConfig } = this.lazyCmp;
    inputConfig
      .filter(inputConfigName => {
        /* extra kulcsok kezelese */
        if (inputConfigName === LIVE_AUTO_COMPLETE_LAZY_OPTION_ELEM_OPTIONS_MODEL_KEY) {
          this.lazyCmp[LIVE_AUTO_COMPLETE_LAZY_OPTION_ELEM_OPTIONS_MODEL_KEY] = item;
          return false;
        }
        return true;
      })
      .forEach(inputConfigName => {
        const foundOption = Object.entries(options).find(optionEntry => optionEntry[0] === inputConfigName);
        if (!isNil(foundOption)) {
          this.lazyCmp[inputConfigName] = foundOption[1];
        } else {
          console.warn('missing input config:', inputConfigName);
        }
      });

    // kereso szoveget adjuk at
    if (this.lazyCmp['searchText$'] === null) {
      this.lazyCmp['searchText$'] = new Observable(observer => {
        const subscription = this.searchFormControl.valueChanges
          .pipe(
            startWith(this.searchFormControl.value),
            map(v => (isNil(v) ? '' : v))
          )
          .subscribe(value => observer.next(value));

        return function unsubscribe() {
          subscription.unsubscribe();
        };
      });
    }
  }
}
