import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  CompanyContextState,
  CompanyContextSubscriptionStateModel,
  SubscriptionGoToAction,
  SubscriptionInfoEnum,
} from '@roadrecord/company-context/common';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, timer } from 'rxjs';
import { isNil } from '@roadrecord/type-guard';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { getSubscriptionCallbackUrl } from '@roadrecord/user/common';
import { Router } from '@angular/router';
import { BASIC_DATA_PAGE_PATH, PRICE_SITE_PATH, USER_ROUTE_PATH, VEHICLE_ROUTE_PATH } from '@roadrecord/common/common';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-subscription-display',
  templateUrl: './subscription-display.component.html',
  styleUrls: ['./subscription-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDisplayComponent implements OnInit, OnDestroy {
  @SelectSnapshot(CompanyContextState.subscriptionState)
  readonly subscriptionInfo: SubscriptionInfoEnum;

  @SelectSnapshot(CompanyContextState.subscription)
  readonly subscriptionState: Partial<CompanyContextSubscriptionStateModel>;

  @Select(CompanyContextState.subscriptionState)
  subscriptionInfo$: Observable<SubscriptionInfoEnum>;

  SubscriptionInfoEnumValues = SubscriptionInfoEnum.getObjectValues();
  private clickHandler: () => void;
  /* azert kell, mert egy egy forditas megletetol fuggoen, tehat ha nincs akkor nem szabad megjelennie */
  show = false;
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private store: Store,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.store
      .select(CompanyContextState.subscriptionState)
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged()
        /*
        tap(item => console.log('SubscriptionDisplayComponent', item))
*/
      )
      .subscribe(subscription => {
        this.show = !isNil(
          this.translocoService.translateObject(`USER.SUBSCRIPTION.DISPLAY.${this.SubscriptionInfoEnumValues[subscription]}`)
        );
        if (this.ngZone.isStable) {
          this.attachClickHandlerToTranslatedText(subscription);
        } else {
          this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(() => {
            this.attachClickHandlerToTranslatedText(subscription);
          });
        }
      });
  }

  private attachClickHandlerToTranslatedText(subscription: SubscriptionInfoEnum) {
    if (subscription !== SubscriptionInfoEnum.VALID_SUBSCRIPTION) {
      const elemRef = document.getElementById('user-subscription-redirect-link');
      if (elemRef === null) {
        return timer(500)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.attachClickHandlerToTranslatedText(subscription));
      }
      this.unsubscribeClickHandler();
      this.clickHandler = this.renderer.listen(elemRef, 'click', event => {
        event.stopPropagation();
        event.preventDefault();

        /*
        #KIADASHOZ-REGI
        this.store.dispatch(
          new SubscriptionGoToAction(
            {
              linkName: elemRef.innerText,
              windowTarget: '_blank',
            },
            getSubscriptionCallbackUrl(subscription)
          )
        );
        */
        if (this.isUsAppType === true) {
          switch (subscription) {
            case SubscriptionInfoEnum.NO_SUBSCRIPTION:
            case SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION:
            case SubscriptionInfoEnum.EXPIRING_SUBSCRIPTION:
              this.router.navigate([`/${USER_ROUTE_PATH}/${PRICE_SITE_PATH}/`]);
              break;
            case SubscriptionInfoEnum.HAS_AVAILABLE_SUBSCRIPTION:
              this.router.navigate([`/${BASIC_DATA_PAGE_PATH}/${VEHICLE_ROUTE_PATH}`]);
              break;
            default:
              this.store.dispatch(
                new SubscriptionGoToAction(
                  {
                    linkName: elemRef.innerText,
                    windowTarget: '_blank',
                  },
                  getSubscriptionCallbackUrl(subscription)
                )
              );
              break;
          }
        } else {
          this.store.dispatch(
            new SubscriptionGoToAction(
              {
                linkName: elemRef.innerText,
                windowTarget: '_blank',
              },
              getSubscriptionCallbackUrl(subscription)
            )
          );
        }
      });
    }
  }

  private unsubscribeClickHandler() {
    if (!isNil(this.clickHandler)) {
      // elozorol leiratkozas ha van
      this.clickHandler();
      delete this.clickHandler;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeClickHandler();
  }
}
