import { NgModule } from '@angular/core';
import { NavToolbarComponent } from './nav-toolbar/nav-toolbar.component';
import { NavToolbarHelpMenuComponent } from './nav-toolbar-help-menu/nav-toolbar-help-menu.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { AppTypePipeModule, IsNilPipeModule, IsPresentPipeModule, SanitizePipeModule } from '@roadrecord/common/common';
import { TextEllipsisModule } from '@roadrecord/text-ellipsis';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgOverrideModule } from '@roadrecord/ng-override';
import { StickyNotificationBarModule } from '@roadrecord/common-components';

@NgModule({
  declarations: [NavToolbarComponent, NavToolbarHelpMenuComponent],
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    CommonModule,
    FlexModule,
    MatIconModule,
    IsPresentPipeModule,
    TextEllipsisModule,
    IsNilPipeModule,
    SanitizePipeModule,
    AppTypePipeModule,
    MatToolbarModule,
    NgOverrideModule,
    StickyNotificationBarModule,
  ],
  exports: [NavToolbarComponent],
})
export class NavToolbarModule {}
